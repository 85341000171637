import React, { useState, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AuthService from '../../../../../services/auth.service';
import { LanguageContext } from '../../../../../language_context';
import { generalStyle } from '../../../../../generalStyle';
import CloseIcon from '@material-ui/icons/Close';
import { cancelBookingDialogStyle } from '../../../../employeebooking/bookingoverview/employeeactions/cancelbookingdialog/cancel_booking_dialog_style';

export const SwapEmployeesDialog = ({ selectedBookings, handleRefreshData, breakpoint, snackbarshow }) => {
  const { lang } = useContext(LanguageContext);
  const generalClass = generalStyle();
  const cancelBookingDialogClass = cancelBookingDialogStyle();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSwapBookings = async () => {
    let body = {
      bookingId1: selectedBookings[0].BookingId,
      bookingId2: selectedBookings[1].BookingId
    };

    let response = await AuthService.fetch(`${AuthService.API_URL}/employee/bookings-swap`, { method: 'post', data: body });

    if (response.data === 'Success') {
      handleRefreshData();
      snackbarshow(lang.swappedEmployeebookingsSuccess);
      handleClose();
    }
  };

  return (
    <div>
      <span onClick={handleClickOpen}>
        {lang.swapEmployeesBtn}
      </span>
      <Dialog
        className={generalClass.dialogWrap}
        maxWidth='xs'
        fullWidth='true'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {breakpoint && <CloseIcon className={generalClass.CloseIcon} />}
        <DialogTitle id="alert-dialog-title">{lang.swapEmployeesDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>{selectedBookings[0].Email}</div>
            <div>{selectedBookings[0].Name}</div>
            <div>{selectedBookings[0].Phone}</div>
            <div>{selectedBookings[0].Date}</div>
            <div>{selectedBookings[0].Start} - {selectedBookings[0].End}</div>
            <br />
            <div>Ombyttes med</div>
            <br />
            <div>{selectedBookings[1].Email}</div>
            <div>{selectedBookings[1].Name}</div>
            <div>{selectedBookings[1].Phone}</div>
            <div>{selectedBookings[1].Date}</div>
            <div>{selectedBookings[1].Start} - {selectedBookings[1].End}</div>
          </DialogContentText>
        </DialogContent>

        {breakpoint ? (
          <DialogActions>
            <button className={cancelBookingDialogClass.cancelBtn} onClick={() => { handleSwapBookings() }}>
              {lang.swapEmployeesDialogAccept}
            </button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>
              {lang.close}
            </Button>
            <button className={cancelBookingDialogClass.cancelBtn} onClick={() => { handleSwapBookings() }}>
              {lang.swapEmployeesDialogAccept}
            </button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
