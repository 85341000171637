import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/';

export const BookingLimitDialog = ({ openBookingLimitDialog, handleBookingLimitDialog }) => {
    const handleClickOpen = () => {
        handleBookingLimitDialog();
    };

    const handleClose = () => {
        handleBookingLimitDialog();
    };

    return (
        <Dialog
            open={openBookingLimitDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Booking limit reached</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">You have reached your maximum number of bookings for this month.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>Close</Button>
            </DialogActions>
        </Dialog>
    );
}