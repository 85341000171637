// import { makeStyles, withStyles } from "@material-ui/core/styles";
// import { Colors } from './constants/colors';
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";

// export const homeStyle = makeStyles((theme) => ({
//     tabBar: {
//         // Dont delete, its magic bby
//     },

//     tabRoot: {
//         borderTopLeftRadius: '5px',
//         borderTopRightRadius: '5px',
//         display: 'inline-flex'
//     },

//     tabContent: {
//         backgroundColor: 'white',
//         border: '1px solid ' + Colors.backgroundColor,
//         borderBottomLeftRadius: '5px',
//         borderBottomRightRadius: '5px',
//     },

//     personalCompanyHeader: {
//         fontSize: '44px',
//         textAlign: 'center',
//         margin: '4px 0 48px 0',
//         '& span:first-child': {
//             color: Colors.primaryColor,
//         }
//     },
// }));

// export const StyledTabs = withStyles({
//     root: {
//         //borderBottom: "1px solid #e8e8e8"
//     },
//     indicator: {
//         backgroundColor: "transparent"
//     }
// })(Tabs);

// export const StyledTab = withStyles((theme) => ({
//     root: {
//         boxShadow: '0 0 black',
//         backgroundColor: Colors.primaryWhite,
//         color: Colors.primaryColor,
//         fontWeight: '600',
//         "&:hover": {
//             backgroundColor: Colors.primaryColor,
//             color: Colors.primaryWhite,
//         },
//         "&$selected": {
//             color: Colors.primaryWhite,
//             backgroundColor: Colors.primaryColor,
//         },

//     },
//     selected: {}
// }))((props) => <Tab disableRipple {...props} />);

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Colors } from '../../constants/colors';
import { BreakPoints } from '../../constants/breakpoints';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

export const homeStyle = makeStyles((theme) => ({
    tabBar: {
        padding: '0px 16px'
        // Dont delete, its magic bby
    },

    tabBarPhone: {
        boxShadow: '0 0 black',
        backgroundColor: Colors.primaryWhite,
        color: Colors.primaryColor,
        transition: '0.3s',
        transform: 'translateY(-100%)',
        width: '100%',
        position: 'absolute',
        zIndex: '999'
    },

    tabBarPhoneOpen: {
        boxShadow: '0 0 black',
        backgroundColor: Colors.primaryWhite,
        color: Colors.primaryColor,
        transition: '0.3s',
        transform: 'translateY(-0%)',
        width: '100%',
        position: 'absolute',
        zIndex: '999'
    },

    tabPanel: {
        backgroundColor: Colors.primaryWhite,

        [theme.breakpoints.down(BreakPoints.main)]: {
            background: 'none',
            height: 'calc(100% - 112px)',

            '&>div': {
                height: '100%',
                padding: '0',

                '&>div': {
                    height: '100%',

                    '&>div': {
                        height: '100%',
                        padding: '24px'
                    },
                },
            },
          },
        //   24
    },

    tabRoot: {
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        display: 'inline-flex',
        border: 'none',

        [theme.breakpoints.down(BreakPoints.main)]: {
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            width: '100%',

            '& div': {
                '& div': {
                    flexDirection: 'column',
                    width: '100%',
                    maxWidth: '100%',

                    '& button': {
                        maxWidth: '100%',
                        justifyContent: 'left !important',
                        opacity: '1',
                        
                        '& span': {
                            flexDirection: 'row',
                            justifyContent: 'left',
                            // justifySelf: 'left !important',
                        }
                    }
                },
            },
        },
    },

    overlay: {
        position: 'fixed',
        height: '100vh',
        width: '100%',
        zIndex: '999',
        backgroundColor: 'black',
        animation: `$fadeIn 0.3s ${theme.transitions.easing.easeInOut}`,
        animationFillMode: 'forwards',
    },

    "@keyframes fadeIn": {
        from: {opacity: "0"},
        to: {opacity: "0.5"},  
    },

    tabContent: {
        backgroundColor: 'white',
        border: '1px solid ' + Colors.primaryWhite,
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',

        [theme.breakpoints.down(BreakPoints.main)]: {
            border: 'none',
            height: 'calc(100% - 112px)',

            '&>div': {
                height: '100%',
                padding: 0,

                '&>div': {
                    '&>div': {
                        padding: '0 15px',
                    },
                },
            },
        },
    },

    logOutBut: {
        cursor: 'pointer'
    },

    MobileNavBut: {
        textTransform: 'none !important',
        zIndex: '1000',
        backgroundColor: Colors.primaryWhite,
        color: Colors.primaryColor,
        WebkitBoxShadow: '0px 1px 2px 0px rgba(0,0,0,0.25)',
        mozBoxShadow: '0px 1px 2px 0px rgba(0,0,0,0.25)',
        boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.25)',
        fontWeight: 'bold',
        display: 'none',
        position: 'relative',
        borderRadius: '0px',
        border: 'none',
        height: '48px',
        width: '100%',
        textAlign: 'left',
        padding: '6px 24px',

        "&:hover": {
            backgroundColor: Colors.primaryWhite,
        },

        '& .SVGBut': {
            position: 'absolute',
            margin: '0 12px',
            right: '0',
        },

        [theme.breakpoints.down(BreakPoints.main)]: {
            display: 'initial',
        },

        '& svg': {
            transition: '0.3s',
        },
    },

    MobileNavButActive: {
        backgroundColor: '#E9F0F3 !important',

        '& svg': {
            transform: 'rotate(180deg)',
        },
    },

    personalCompanyHeader: {
        fontSize: '44px',
        textAlign: 'center',
        margin: '4px 0 48px 0',

        '& span:first-child': {
            color: Colors.primaryColor,
        },

        [theme.breakpoints.down(BreakPoints.main)]: {
            display: 'none',
          },
    },
}));

export const StyledTabs = withStyles({
    root: {
        //borderBottom: "1px solid #e8e8e8"
    },

    indicator: {
        backgroundColor: "transparent"
    }
})(Tabs);

export const StyledTab = withStyles((theme) => ({
    root: {
        boxShadow: '0 0 black',
        backgroundColor: Colors.primaryWhite,
        color: Colors.primaryColor,
        fontWeight: '600',

        [theme.breakpoints.down(BreakPoints.main)]: {
            fontWeight: '100',
            textTransform: 'none !important',
        },

        "&:hover": {
            backgroundColor: Colors.primaryColor,
            color: Colors.primaryWhite,

            [theme.breakpoints.down(BreakPoints.main)]: {
                backgroundColor: Colors.primaryWhite,
                color: Colors.primaryColor,
            },
        },

        "&$selected": {
            color: Colors.primaryWhite,
            backgroundColor: Colors.primaryColor,

            [theme.breakpoints.down(BreakPoints.main)]: {
                display: 'none',
            },
        },
    },

    selected: {}
}))((props) => <Tab disableRipple {...props} />);