import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../constants/colors';

export const CompanyEmployeesStyle = makeStyles((theme) => ({
    showHistoryBtn: {
        fontSize: '14px !important',
        fontWeight: '300 !important',
        cursor: 'pointer !important',
        color: Colors.grey + '!important',
        textAlign: 'end',
    },

    noBookings: {
        fontSize: '16 !important',
        fontWeight: '300 !important',
        color: Colors.grey + ' !important',
        marginTop: '16px !important',
    },

    buttonWrap: {
        marginBottom: '42px',

        '& div:last-child': {
            border: 'none',
        },
        
        '& span': {
            textTransform: 'none !important',
        },
    },

    ContentHeight: {
        '&>div': {
            height: '80px !important',
          },
    },

    buttonContentWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        
        '& div': {
            maxWidth: '40%',

            '& p': {
                padding: '4px 40px !important',
            },
        },

        '& div:last-child': {
            textAlign: 'right',

            '& p': {
                color: Colors.primaryColor,
                padding: '4px 8px 4px 0 !important',
            },
        },
    }
    // extraInfo: {
    //     height: '0px',
    //     overflow: 'hidden',
    // }
}));