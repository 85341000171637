import { BookingOverview } from './bookingoverview/booking_overview'

export const EmployeeBookingView = ({ employeeDetails, setEmployeeDetails, company, handleSetNavIndex, getCompanyFull, getEmployee, breakpoint, currentUser, snackbarshow, setLoading }) => {
    const employeeBookingViewProps = { employeeDetails, setEmployeeDetails, company, handleSetNavIndex, getCompanyFull, getEmployee, breakpoint, currentUser, snackbarshow, setLoading };

    return (
        <div>
            {employeeDetails !== undefined ? <BookingOverview {...employeeBookingViewProps} /> : <p>Henter dine informationer</p>}
        </div>
    )
}