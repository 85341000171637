import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../constants/colors';

export const BookingOverviewStyle = makeStyles((theme) => ({
    tableStyle: {
        '& div:nth-child(2)': {
            boxShadow: 'none',
        },
    },

    overviewTitle: {
        marginBottom: '8px',
    },

    showHistoryBtn: {
        fontSize: '14px !important',
        fontWeight: '300 !important',
        cursor: 'pointer !important',
        color: Colors.grey + '!important',
        textAlign: 'end',
    },

    noBookings: {
        fontSize: '16 !important',
        fontWeight: '300 !important',
        color: Colors.grey + ' !important',
        marginTop: '16px !important',
    },

    DropButtonContentHeight: {
        height: 'calc(38px * 3)'
    },
    
    buttonWrap: {
        marginBottom: '42px',
        '& div:last-child': {
            border: 'none',
        },
    },

    BookingDropButtonPrev: {
        WebkitBoxShadow: '0px 1px 2px 0px rgba(0,0,0,0)',
        mozBoxShadow: '0px 1px 2px 0px rgba(0,0,0,0)',
        boxShadow: '0px 1px 2px 0px rgba(0,0,0,0)',

        '& button': {
            backgroundColor: 'transparent',
            color: 'grey',

            '& .SVGBut': {
                fill: 'grey',
            },

            '&:hover': {
                backgroundColor: 'transparent',
            },
        },

        '&>div': {
            backgroundColor: 'transparent',
        },

        '& span': {
            pointerEvents: 'none',
            height: 'max-content !important',
            '&>span': {
                paddingLeft: '8px'
            },
        },
        
        '& p': {
            paddingLeft: '26px !important',
        },
    },

    ContentHeight: {
        '&>div': {
            height: '112px !important',
          },
    },
}));