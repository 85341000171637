import React, { useState, useContext } from 'react';
import { Icon, Button, Dialog } from '@material-ui/core';
import { MasseuseDialogStyle, DialogContent, DialogActions } from './masseuse_dialog_style';
import { LanguageContext } from '../../../language_context';

export const MasseuseDialog = ({ masseuse, masseuseImg, masseuseEducation, masseuseBio }) => {
    const { lang } = useContext(LanguageContext);
    const masseuseDialogClass = MasseuseDialogStyle();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <span onClick={handleClickOpen}>
                {masseuse}
            </span>
            <Dialog
                maxWidth='md'
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <DialogContent dividers>
                    <div className={masseuseDialogClass.header}>
                        <img src={masseuseImg} alt="therapist" width="160" height="160" />
                        <div>{masseuse}</div>
                        <div className={masseuseDialogClass.therapistRating}>
                            <Icon className="fa fa-star" />
                            <Icon className="fa fa-star" />
                            <Icon className="fa fa-star" />
                            <Icon className="fa fa-star" />
                            <Icon className="fa fa-star" />
                            <span>(1)</span>
                        </div>
                    </div>
                </DialogContent>
                <DialogContent dividers>
                    <div className={masseuseDialogClass.education}>
                        <div>{lang.education}</div>
                        <div>{masseuseEducation}</div>
                    </div>
                </DialogContent>
                <DialogContent>
                    <div className={masseuseDialogClass.bio}>
                        <div>{lang.bio}</div>
                        <div>{masseuseBio}</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        {lang.close}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
