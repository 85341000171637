import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../../language_context';
import AuthService from '../../../services/auth.service';
import Logo from '../../../assets/Raskrask-logo-text.png';
import { useHistory, useLocation } from 'react-router-dom';

const Login = ({ setCurrentUser, setLoading }) => {
  const history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };
  const { lang } = useContext(LanguageContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userid, setUserId] = useState('');
  const [companyid, setCompanyId] = useState('');
  const [organiserid, setOrganiserID] = useState('');
  const [CName, setCName] = useState('');
  const [CPhone, setCPhone] = useState('');
  const [CompanyName, setCompanyName] = useState('');
  const [EmailDomain, setEmailDomain] = useState('');
  const [PwProtected, setPwProtected] = useState('');
  const [LoginTrigger, setLoginTrigger] = useState(false);
  const [ValidationStatus, setValidationStatus] = useState(undefined);
  const [APIData, setAPIData] = useState(undefined);
  const [errors, setErrors] = useState({
    login: '',
    email: '',
    password: '',
    CName: '',
    CPhone: '',
    CompanyName: '',
    emaildomain: '',
  });

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    if (LoginTrigger) {
      handleLogin();
    }
  }, [LoginTrigger]);

  /* OnChange events */

  const onChangeEmail = (e) => {
    const email = e.target.value;
    if (email.length === 0) {
      setErrors({ ...errors, email: lang.login_email_warning });
    } else {
      setErrors({ ...errors, email: '' });
    }
    setEmail(email);
    const emailsplit = email.split('@');
    const domain = emailsplit[1];
    setEmailDomain(domain);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    if (password.length === 0) {
      setErrors({ ...errors, password: lang.login_password_warning });
    } else {
      setErrors({ ...errors, password: '' });
    }
    setPassword(password);
  };

  const onChangeCName = (e) => {
    const cname = e.target.value;
    if (cname.length === 0) {
      setErrors({ ...errors, CName: lang.login_cname_warning });
    } else {
      setErrors({ ...errors, CName: '' });
    }
    setCName(cname);
  };

  const onChangeCPhone = (e) => {
    const cphone = e.target.value;
    if (cphone.length !== 8) {
      setErrors({ ...errors, CPhone: lang.login_cphone_warning });
    } else {
      setErrors({ ...errors, CPhone: '' });
    }
    setCPhone(cphone);
  };

  const onChangeCompanyName = (e) => {
    const companyname = e.target.value;
    if (companyname.length === 0) {
      setErrors({
        ...errors,
        CompanyName: lang.login_companyname_warning,
      });
    } else {
      setErrors({ ...errors, CompanyName: '' });
    }
    setCompanyName(companyname);
  };

  /* FIRST LOAD */

  const checkEmail = async (e) => {
    e.preventDefault();
    const body = {
      Email: email,
    };
    const response = await AuthService.fetch(
      `${AuthService.API_URL}/checkemail`,
      { method: 'post', data: body }
    );
    if (response.data[0] !== undefined && response.data[0].length > 0) {
      const mydata = response.data[0];
      setAPIData(mydata);
      if (response.data[0].length === 1) {
        const myuserid = mydata[0].User_ID;
        setUserId(myuserid);
        const pwprotected = response.data[0][0].PwProtected;
        setPwProtected(pwprotected);
        const mycompanyid = response.data[0][0].Company_ID;
        setCompanyId(mycompanyid);
      }
      const status = response.data[0][0].Status;
      setCompanyName(response.data[0][0].companyName);
      setValidationStatus(status);
    } else {
      setValidationStatus(undefined);
    }
  };

  /* Login */

  const handleLoginButtonTrigger = (e) => {
    e.preventDefault();
    handleLogin();
    //here access token is returned! - user is authenticated so log him in!
  };

  const handleLogin = async () => {
    const var1 = userid;
    const var2 = password;
    const response = await AuthService.login(var1, var2);
    setCurrentUser(response);
    history.replace(from);
  };

  /* Missing Info */

  const createPassword = async (e) => {
    e.preventDefault();
    const body = {
      User_ID: userid,
      Password: password,
    };
    const response = await AuthService.fetch(
      `${AuthService.API_URL}/createpassword`,
      { method: 'post', data: body }
    );
    // console.log(response);
    if (response.data === 'Success') {
      setLoginTrigger(true);
    } else {
      console.log('unexpeced result returned');
    }
  };

  const handlepickbranch = async (e, mycompanyid, pwprotected) => {
    e.preventDefault();
    setCompanyId(mycompanyid);
    setPwProtected(pwprotected);
    const mystatus = 'Company found, but not user';
    setValidationStatus(mystatus);
  };

  const handleOrganiserQuestion = async (e, pick) => {
    e.preventDefault();
    if (pick === 'Ja') {
      setValidationStatus('pickorganiser');
    } else {
      setValidationStatus(
        'Did not find user or company, and there is NOT any organiser'
      );
    }
  };

  const handlepickorganiser = async (e, companyid) => {
    e.preventDefault();
    setOrganiserID(companyid);
    const mystatus = 'CompanySignUp';
    setValidationStatus(mystatus);
    // display sub company sign up view
  };

  const handlecreatesubcompany = async (e) => {
    e.preventDefault();
    const body = {
      CompanyName: CompanyName,
      EmailDomain: EmailDomain,
      Company_ID: organiserid,
    };
    const response = await AuthService.fetch(
      `${AuthService.API_URL}/createsubcompany`,
      { method: 'post', data: body }
    );
    if (response.data[0][0] !== undefined) {
      const pwprotected = response.data[0][0].PwProtected;
      const mycompanyid = response.data[0][0].Company_ID;
      setPwProtected(pwprotected);
      setCompanyId(mycompanyid);
      setValidationStatus('Company found, but not user');
    }
  };

  /* Signup */

  const handlesignupbutton = (e) => {
    e.preventDefault();
    handlesignup();
  };

  const handlesignup = async () => {
    const body = {
      Password: password,
      Name: CName,
      Phone: CPhone,
      Email: email,
      Company_ID: companyid,
    };
    const response = await AuthService.fetch(`${AuthService.API_URL}/signup`, {
      method: 'post',
      data: body,
    });

    if (response.data.User_ID !== undefined) {
      const auserid = response.data.User_ID;
      setUserId(auserid);
      setLoginTrigger(true);
    } else {
      console.log('something gone wrong');
    }
  };

  function getLoginView(status) {
    switch (status) {
      case 'User found but a password is required':
        return (
          <div className="login-container">
            <div className="login">
              <img className="logo" src={Logo} alt="RaskRask Logo" />
              <form className="login-form">
                <div className="input-group">
                  <label>{lang.login_email_text}:</label>
                  <input
                    type="email"
                    name="email"
                    onChange={onChangeEmail}
                    value={email}
                    required
                  />
                  {errors.email !== '' && (
                    <span className="warning">{errors.email}</span>
                  )}
                </div>
                <div className="input-group">
                  <label>{lang.login_password}:</label>
                  <input
                    type="password"
                    name="password"
                    onChange={onChangePassword}
                    value={password}
                    required
                  />
                </div>
                <button
                  className="btn save-btn"
                  onClick={handleLoginButtonTrigger}
                  type="submit"
                  disabled={email === '' || password === ''}
                >
                  {lang.nextStep}
                </button>
              </form>
            </div>
          </div>
        );
      case 'User found, a password is required but the user has no password':
        return (
          <div className="login-container">
            <div className="login">
              <img className="logo" src={Logo} alt="RaskRask Logo" />
              <form className="login-form">
                <div className="input-group">
                  <p>{lang.login_create_password}</p>
                </div>
                <div className="input-group">
                  <label>{lang.login_password}:</label>
                  <input
                    name="password"
                    type="password"
                    value={password}
                    onChange={onChangePassword}
                    placeholder="Skriv et kodeord"
                    pattern=".{4,32}"
                    required
                  ></input>
                  {errors.password !== '' && (
                    <span className="warning">{errors.password}</span>
                  )}
                </div>
                <button
                  className="btn save-btn"
                  onClick={createPassword}
                  type="submit"
                  disabled={email === '' || password === ''}
                >
                  {lang.login_create_password}
                </button>
              </form>
            </div>
          </div>
        );
      case 'User found and no password is required':
        handleLogin();
        return (
          <div className="login-container">
            <div className="login">
              <img className="logo" src={Logo} alt="RaskRask Logo" />
              <div>{lang.login_logging_in}</div>
            </div>
          </div>
        );
      case 'Company found, but not user':
        return (
          <div className="login-container">
            <div className="login">
              <img className="logo" src={Logo} alt="RaskRask Logo" />
              <form className="login-form">
                <div className="input-group">
                  <p>
                    {lang.login_create_user_header} {CompanyName}
                  </p>
                </div>
                <div className="input-group">
                  <div>{lang.full_name}:</div>
                  <input
                    type="text"
                    name="CName"
                    onChange={onChangeCName}
                    value={CName}
                    required
                  />
                  {errors.CName !== '' && (
                    <span className="warning">{errors.CName}</span>
                  )}
                </div>
                <div className="input-group">
                  <div>{lang.mobile_number}</div>

                  <input
                    type="text"
                    name="CPhone"
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .replace(/\+/g, '')
                        .replace(/[^\d]/g, '');
                      onChangeCPhone(e);
                    }}
                    value={CPhone}
                    required
                  />
                  <p style={{ fontSize: '13px' }}>
                    {lang.login_create_user_phone_number_explanation}
                  </p>
                  {errors.CPhone !== '' && (
                    <span className="warning">{errors.CPhone}</span>
                  )}
                </div>
                {PwProtected === 1 && (
                  <div className="input-group">
                    <div>{lang.login_password}</div>
                    <input
                      name="password"
                      type="password"
                      value={password}
                      onChange={onChangePassword}
                      placeholder="Skriv et kodeord"
                      pattern=".{4,32}"
                      required
                    ></input>
                    {errors.password !== '' && (
                      <span className="warning">{errors.password}</span>
                    )}
                  </div>
                )}
                <button
                  className="btn save-btn"
                  onClick={handlesignupbutton}
                  type="submit"
                  disabled={
                    email === '' ||
                    CPhone.length !== 8 ||
                    CName === '' ||
                    (password === '' && PwProtected === 1)
                  }
                >
                  {lang.login_sign_up}
                </button>
              </form>
            </div>
          </div>
        );
      case 'More than one addresses':
        return (
          <div className="login-container">
            <div className="login">
              <img className="logo" src={Logo} alt="RaskRask Logo" />
              <form className="login-form">
                <div className="input-group">
                  <p>{lang.login_choose_department}</p>
                </div>
                {APIData.map((el) => {
                  return (
                    <div className="input-group" key={el.Company_ID}>
                      <button
                        className="btn save-btn"
                        onClick={(e) => {
                          handlepickbranch(e, el.Company_ID, el.PwProtected);
                        }}
                      >
                        <div>{el.CompanyName}</div>
                        <div>{el.CompanyAddress}</div>
                      </button>
                    </div>
                  );
                })}
              </form>
            </div>
          </div>
        );
      case 'Did not find user or company, and there are organisers':
        return (
          <div className="login-container">
            <div className="login">
              <img className="logo" src={Logo} alt="RaskRask Logo" />
              <form className="login-form">
                <div className="input-group">
                  <p>{lang.login_not_recognised_domain}</p>
                </div>
                <div className="input-group">
                  <button
                    className="btn save-btn"
                    onClick={(e) => {
                      handleOrganiserQuestion(e, 'Ja');
                    }}
                  >
                    {lang.yes}
                  </button>
                </div>
                <div className="input-group">
                  <button
                    className="btn save-btn"
                    onClick={(e) => {
                      handleOrganiserQuestion(e, 'Nej');
                    }}
                  >
                    {lang.no}
                  </button>
                </div>
              </form>
            </div>
          </div>
        );
      case 'Did not find user or company, and there is NOT any organiser':
        return (
          <div className="login-container">
            <div className="login">
              <img className="logo" src={Logo} alt="RaskRask Logo" />
              <div className="input-group">
                <p>{lang.login_access_denied}.</p>
                <br></br>
                <a href="https://firma.raskrask.dk">
                  {lang.login_access_denied_linktext}
                </a>
              </div>
            </div>
          </div>
        );
      case 'pickorganiser':
        return (
          <div className="login-container">
            <div className="login">
              <img className="logo" src={Logo} alt="RaskRask Logo" />
              <form className="login-form">
                {APIData.map((el) => {
                  return (
                    <div className="input-group" key={el.Company_ID}>
                      <button
                        className="btn save-btn"
                        onClick={(e) => {
                          handlepickorganiser(e, el.Company_ID);
                        }}
                      >
                        <div>{el.CompanyName}</div>
                        <div>{el.CompanyAddress}</div>
                      </button>
                    </div>
                  );
                })}
              </form>
            </div>
          </div>
        );
      case 'CompanySignUp':
        return (
          <div className="login-container">
            <div className="login">
              <img className="logo" src={Logo} alt="RaskRask Logo" />
              <form className="login-form">
                <div className="input-group">
                  <p>{lang.login_please_fill_all_fields}</p>
                </div>
                <div className="input-group">
                  <div>{lang.company_name}</div>
                  <input
                    type="text"
                    name="CompanyName"
                    onChange={onChangeCompanyName}
                    value={CompanyName}
                    required
                  />
                  {errors.CompanyName !== '' && (
                    <span className="warning">{errors.CompanyName}</span>
                  )}
                </div>
                <div className="input-group">
                  <div>{lang.email_domain}</div>
                  <input
                    type="text"
                    name="EmailDomain"
                    value={EmailDomain}
                    disabled={true}
                    required
                  />
                </div>
                <button
                  className="btn save-btn"
                  onClick={handlecreatesubcompany}
                  type="submit"
                  disabled={CompanyName === '' || EmailDomain === ''}
                >
                  {lang.login_sign_up}
                </button>
              </form>
            </div>
          </div>
        );
      default:
        return (
          <div className="login-container">
            <div className="login">
              <img className="logo" src={Logo} alt="RaskRask Logo" />
              <form className="login-form">
                <div className="input-group">
                  <label>{lang.login_email_text}:</label>
                  <input
                    type="email"
                    name="email"
                    onChange={onChangeEmail}
                    value={email}
                    required
                  />
                  {errors.email !== '' && (
                    <span className="warning">{errors.email}</span>
                  )}
                </div>
                <button
                  className="btn save-btn"
                  onClick={checkEmail}
                  type="submit"
                  disabled={email === ''}
                >
                  {lang.nextStep}
                </button>
              </form>
            </div>
          </div>
        );
    }
  }

  return getLoginView(ValidationStatus);
};

export default Login;
