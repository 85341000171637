import { RRDate } from '@raskrask-dk/raskrask-utils';
export const retrieveTimeslots = (date, companyObject, selectedBooking) => {
  var base = companyObject.consecutiveTimeslots;
  var sessionInfo = createTimeslots(date, companyObject, selectedBooking);
  if (base !== null) {
    var timeslots = sessionInfo.timeslots;

    // remove all TS that is booked
    var openSlots = timeslots.filter((ts) => ts.Available === true);
    var openOne = null;
    var openTwo = null;

    for (var i = 0; i < openSlots.length; i++) {
      // find the initial timeslot
      if (base <= openSlots[i].Start) {
        openOne = openSlots[i];

        if (i > 0) {
          openTwo = openSlots[i - 1];
        }
        // stops the loop if the TS('s) are found
        break;
      }
    }
    // if the initial TS is not found because base is not within the
    // interval of available TS, then take the last in the array.
    if (openOne === null && openSlots.length > 0) {
      openOne = openSlots[openSlots.length - 1];
    }

    // another loop?
    // manipulate the input array with the open slots
    timeslots.forEach((ts) => {
      if (openOne !== null && ts.Start === openOne.Start) {
        ts.Open = true;
      } else if (openTwo !== null && ts.Start === openTwo.Start) {
        ts.Open = true;
      } else {
        ts.Open = false;
      }
    });

    return { ...sessionInfo, timeslots: timeslots };
  } else {
    return sessionInfo;
  }
};

export function ShrinkObject(companyObj, employeeDetails) {
  //check if employee is a subcompany
  if (employeeDetails.Sub_ID) {
    companyObj.bookings.filter(
      (el) =>
        el.MotherBookingCompanyId === employeeDetails.Sub_ID ||
        el.MotherBookingSubId === employeeDetails.Sub_ID
    );
  } else {
    companyObj.bookings.filter(
      (el) =>
        el.MotherBookingCompanyId === employeeDetails.Company_ID ||
        el.MotherBookingSubId === employeeDetails.Company_ID
    );
  }
  companyObj.dates = companyObj.dates.filter(
    (el) => companyObj.bookings.filter((el2) => el2.Date === el.Date).length > 0
  );
  return companyObj;
}

const checkIfBreakTimeIsWithinService = (
  serviceDate,
  breakStartTime,
  durationExcBreak
) => {
  const serviceStart = new RRDate(serviceDate);
  const serviceEnd = new RRDate(serviceDate).plus({
    minutes: durationExcBreak,
  });
  const breakStart = new RRDate(serviceDate).set({
    hour: breakStartTime.substring(0, 2),
    minute: breakStartTime.substring(3, 5),
  });
  const breakEnd = breakStart.addMinutes(29);
  const res = breakEnd.overlap({ from: serviceStart, to: serviceEnd });
  return res;
};

export function createTimeslots(date, companyObject, selectedBooking) {
  var fullSessionInfo = {
    masseuse: '',
    masseuseImg: '',
    masseuseEducation: '',
    masseuseBio: '',
    deadline: companyObject.employeeBookingDeadline,
    employeeDuration: 0,
    timeslots: [],
    CompanySetting_Message: '',
  };
  if (date !== undefined) {
    var currentDate = new Date().getTime();

    var mother = companyObject.bookings.filter((el) => el.Date === date)[0];

    var duration = parseInt(mother.Duration);
    var employeeDuration = parseInt(companyObject.employeeBookingDuration);

    var nmbtimeslots = (duration / employeeDuration).toFixed(0);
    var start = mother.Start;
    var end = addMinutes(start, employeeDuration);
    var breakStart = companyObject.breakStartTime;
    var breakEnd;

    const isBreakWithServiceDuration =
      selectedBooking && companyObject.breakStartTime !== null
        ? checkIfBreakTimeIsWithinService(
            `${selectedBooking.Date} ${selectedBooking.Start}:00`,
            companyObject.breakStartTime,
            selectedBooking.Duration
          )
        : false;
    if (isBreakWithServiceDuration) {
      breakStart = companyObject.breakStartTime.substring(0, 5);
      breakEnd = addMinutes(
        breakStart,
        employeeDuration * companyObject.breakTimeslots
      );
      nmbtimeslots = (
        parseInt(nmbtimeslots) + companyObject.breakTimeslots
      ).toFixed(0);
    }

    var timeslots = [];

    for (var i = 0; i < nmbtimeslots; i++) {
      var deadlineBoundary = new Date(`${date}T${start}:00`);
      deadlineBoundary = deadlineBoundary.setHours(
        deadlineBoundary.getHours() - 0
      );

      if (deadlineBoundary >= currentDate) {
        if (!(breakStart !== null && breakStart <= start && end <= breakEnd)) {
          var slot = {
            MotherBookingId: mother.MotherBookingId,
            BookingId: '',
            CustomerId: '',
            Start: start,
            End: end,
            Date: date,
            Available: true,
            Open: true,
            Name: '',
            Email: '',
            Phone: '',
            Selected: false,
          };
          timeslots.push(slot);
        }
      }

      start = end;
      end = addMinutes(end, employeeDuration);

      fullSessionInfo.masseuse = mother.Masseuse;
      fullSessionInfo.deadline = companyObject.employeeBookingDeadline;
      fullSessionInfo.employeeDuration = employeeDuration;
      fullSessionInfo.timeslots = checkAvailabilities(
        timeslots,
        mother,
        selectedBooking
      );
      fullSessionInfo.masseuseImg = mother.MasseuseImg;
      fullSessionInfo.masseuseEducation = mother.MasseuseEducation;
      fullSessionInfo.masseuseBio = mother.MasseuseBio;
      fullSessionInfo.CompanySetting_Message = mother.CompanySetting_Message;
    }
  }

  return fullSessionInfo;
}

function checkAvailabilities(timeslots, mother, selectedBooking) {
  timeslots.forEach((element) => {
    var overlapping = mother.DaughterBookings.filter(
      (m) => m.Start === element.Start
    );
    if (overlapping.length !== 0) {
      element.Available = false;
      element.Name = nameManipulation(overlapping[0].Name);
      element.BookingId = overlapping[0].BookingId;
      element.CustomerId = overlapping[0].CustomerId;
      element.Email = overlapping[0].Email;
      element.Phone = overlapping[0].Phone;

      if (selectedBooking !== undefined) {
        if (selectedBooking.BookingId === overlapping[0].BookingId) {
          element.Move = true;
        } else {
          element.Move = false;
        }
      }
    }
  });

  return timeslots;
}

function addMinutes(time, minutes) {
  var addTime = time.split(':');
  var m = parseInt(addTime[0]) * 60 + parseInt(addTime[1]);
  var newMinutes = m + minutes;
  var updatedTime =
    ('0' + Math.floor(newMinutes / 60).toString()).slice(-2) +
    ':' +
    ('0' + (newMinutes % 60).toString()).slice(-2);

  return updatedTime;
}

function nameManipulation(name) {
  var nameSplit = name.split(' ');
  var newName = nameSplit[0];

  for (var i = 1; i < nameSplit.length; i++) {
    newName += ` ${nameSplit[i].substring(0, 1)}. `;
  }

  return newName;
}
