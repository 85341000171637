import React, { useState } from 'react';
import { LanguageContext, languages } from '../language_context';
import { App } from '../App';

const LanguageWrapper = () => {
    const [lang, setLang] = useState(languages.DK);

    const appProps = { lang, setLang }

    return (
        <LanguageContext.Provider value={{ lang, setLang }}>
            <App {...appProps} />
        </LanguageContext.Provider>
    )
}

export default LanguageWrapper;