import React, { useState, useEffect, useContext } from 'react';
import { generalStyle, StyledTableCell } from '../../../generalStyle';
import {
  Button,
  Grid,
  Icon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { EmployeesBookingStyle } from './employees_booking_style';
import { createTimeslots } from './employees_booking';
import { LanguageContext } from '../../../language_context';
import { AdminActions } from './adminactions/admin_actions';
import { TimeslotRow } from './timeslotrow/timeslot_row';
import prettydate from '../../common/commonfunctions';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { CancelEmployeeBookingDialog } from './adminactions/cancelemployeebookingdialog/cancel_employee_booking_dialog';
import { ChangeBookingDialog } from './adminactions/changebookingdialog/change_booking_dialog';
import { SwapEmployeesDialog } from './adminactions/swapEmployeesDialog/swap_employees_dialog';
import { BookForEmployee } from './adminactions/bookforemployeedialog/book_for_employee_dialog';

export const EmployeesBookingTable = ({
  parentBooking,
  company,
  getCompanyFull,
  handleSetNavIndex,
  breakpoint,
  snackbarshow,
}) => {
  const { lang } = useContext(LanguageContext);
  const [showBookings, setShowBookings] = useState(false);
  const [fullSessionInfo, setFullSessionInfo] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState();
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const generalClass = generalStyle();
  const employeesBookingClass = EmployeesBookingStyle();

  const hasEmployeeBookings = company.hasEmployeeBookings;

  const handleSetSelectedBookings = (booking) => {
    if (selectedBookings.includes(booking)) {
      setSelectedBookings(selectedBookings.filter((b) => b !== booking));
    } else {
      setSelectedBookings([...selectedBookings, booking]);
      var temptDetails = {
        FullName: booking.Name,
        Phone: booking.Phone,
        Email: booking.Email,
        CustomerId: booking.CustomerId,
        ActiveBookings: [],
      };
      setEmployeeDetails(temptDetails);
    }
    setSelectedTimeslot(null);
  };

  const handleSetSelectedTimeslot = (ts) => {
    if (ts === selectedTimeslot) {
      setSelectedTimeslot(null);
    } else {
      setSelectedTimeslot(ts);
    }
    setSelectedBookings([]);
  };

  const handleRefreshData = () => {
    // also resets the states
    setSelectedBookings([]);
    setSelectedTimeslot(null);
    setEmployeeDetails();
    getCompanyFull();
  };

  const handleShowBooking = () => {
    setShowBookings(!showBookings);
  };

  useEffect(() => {
    setFullSessionInfo(
      createTimeslots(parentBooking.Date, company, parentBooking)
    );
  }, [company]);

  const actionButtonProps = {
    company,
    employeeDetails,
    handleSetNavIndex,
    selectedBookings,
    selectedTimeslot,
    getCompanyFull,
    handleRefreshData,
    breakpoint,
    snackbarshow,
  };

  const changeBookingDialogProps = {
    company,
    employeeDetails,
    handleSetNavIndex,
    selectedBookings,
    getCompanyFull,
    handleRefreshData,
    breakpoint,
    snackbarshow,
  };

  const openPhoneMenu = (e, bookingId) => {
    if (e.target.type !== 'checkbox') {
      !e.target.parentNode.parentNode.classList.contains(
        `${generalClass.DropButtonActive}`
      )
        ? e.target.parentNode.parentNode.classList.add(
            `${generalClass.DropButtonActive}`,
            `${employeesBookingClass.ContentShow}`
          )
        : e.target.parentNode.parentNode.classList.remove(
            `${generalClass.DropButtonActive}`,
            `${employeesBookingClass.ContentShow}`
          );
    } else {
      //   handleSelect(bookingId)
    }
  };

  const GetParentEnd = () => {
    // console.log(fullSessionInfo);
    return fullSessionInfo.timeslots[fullSessionInfo.timeslots.length - 1].End;
  };

  return fullSessionInfo.timeslots !== undefined ? (
    breakpoint ? (
      <div className={employeesBookingClass.dropDownMobile}>
        <div className={employeesBookingClass.DropButton}>
          <Button onClick={(e) => openPhoneMenu(e)}>
            <span className={'DropButtonTitle'}>{parentBooking.Date}</span>
            <ArrowDropDownIcon className={'SVGBut'}></ArrowDropDownIcon>
          </Button>

          <div className={employeesBookingClass.DropButtonContent}>
            <div className={employeesBookingClass.mobileTableHeader}>
              <p>{lang.name}</p>
              <p>{lang.time}</p>
            </div>

            {fullSessionInfo.timeslots.map((row, index) => (
              <TimeslotRow
                key={index}
                {...{
                  row,
                  selectedTimeslot,
                  selectedBookings,
                  handleSetSelectedBookings,
                  handleSetSelectedTimeslot,
                  breakpoint,
                }}
              />
            ))}
          </div>
        </div>
        {selectedBookings.length > 0 && (
          <div className={generalClass.BottomMenu}>
            <CancelEmployeeBookingDialog
              {...{
                selectedBookings,
                handleRefreshData,
                breakpoint,
                snackbarshow,
              }}
            />
            {selectedBookings.length < 2 ? (
              <ChangeBookingDialog {...changeBookingDialogProps} />
            ) : (
              <SwapEmployeesDialog
                {...{
                  selectedBookings,
                  handleRefreshData,
                  breakpoint,
                  snackbarshow,
                }}
              />
            )}
          </div>
        )}
        {selectedTimeslot && (
          <div className={generalClass.BottomMenu}>
            <BookForEmployee
              {...{
                company,
                selectedTimeslot,
                handleRefreshData,
                breakpoint,
                snackbarshow,
              }}
            />
          </div>
        )}
      </div>
    ) : (
      <div>
        <div className={employeesBookingClass.sectionHeader}>
          <Grid
            onClick={
              hasEmployeeBookings === true ? handleShowBooking : () => {}
            }
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={2}>
              <div style={{ marginLeft: '12px' }}>
                {prettydate(parentBooking.Date)}
              </div>
            </Grid>

            <Grid item xs={2}>
              <div style={{ marginLeft: '12px' }}>
                {parentBooking.Start} - {GetParentEnd(parentBooking.End)}
              </div>
            </Grid>

            <Grid item xs={4}>
              <div style={{ marginLeft: '12px' }}>
                {parentBooking.STypeName}
              </div>
            </Grid>
            <Grid item xs={2}>
              <div style={{ marginLeft: '12px' }}>{parentBooking.Masseuse}</div>
            </Grid>
            {hasEmployeeBookings === true && (
              <Grid item xs={2}>
                <div style={{ marginLeft: '12px' }}>
                  {parentBooking.DaughterBookings.length}/
                  {fullSessionInfo.timeslots.length}
                  {showBookings ? (
                    <Icon className="fa fa-caret-up" />
                  ) : (
                    <Icon className="fa fa-caret-down" />
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </div>
        <div className={employeesBookingClass.sectionContent}>
          {showBookings && fullSessionInfo.timeslots.length > 0 ? (
            <React.Fragment>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell align="left">
                        {lang.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {lang.email}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {lang.phone}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {lang.time}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fullSessionInfo.timeslots.map((row, index) => (
                      <TimeslotRow
                        key={index}
                        {...{
                          row,
                          selectedTimeslot,
                          selectedBookings,
                          handleSetSelectedBookings,
                          handleSetSelectedTimeslot,
                          breakpoint,
                        }}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Buttons for the different actions an admin */}
              <AdminActions {...actionButtonProps} />
            </React.Fragment>
          ) : fullSessionInfo.timeslots.length === 0 ? (
            <div>{lang.noTimeSlotsCreated}</div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    )
  ) : (
    <div>Loading</div>
  );
};
