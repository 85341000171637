import React, { useState, useContext } from 'react';
import { CompanyBookingStyle } from './company_booking_style';
import {
  generalStyle,
  StyledTableCell,
  GreenCheckBox,
} from '../../generalStyle';
import { createTimeslots } from '../employeesbooking/employeesbookingtable/employees_booking';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
// import { CancelBookingDialog } from './cancelbookingdialog/cancel_booking_dialog';
import { ChangeBookingDialog } from './changebookingdialog/change_booking_dialog';
import AuthService from '../../services/auth.service';
import { RequestBookingDialog } from './requestbookingdialog/request_booking_dialog';
import { LanguageContext } from '../../language_context';
import prettydate from '../common/commonfunctions';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export const CompanyBookingView = ({
  company,
  setCompany,
  breakpoint,
  snackbarshow,
}) => {
  const { lang } = useContext(LanguageContext);
  const companyBookingClass = CompanyBookingStyle();
  const generalClass = generalStyle();
  const [selected, setSelected] = useState(undefined); // selected company booking
  const [selectedBooking, setSelectedBooking] = useState(undefined); // selected company booking
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [selectedFromTime, setSelectedFromTime] = useState(undefined);
  const [selectedToTime, setSelectedToTime] = useState(undefined);

  const handleSelect = (bookingId) => {
    if (selected === bookingId) {
      setSelected(undefined);
      setSelectedBooking(undefined);
      setSelectedDate(undefined);
      setSelectedFromTime(undefined);
      setSelectedToTime(undefined);
    } else {
      setSelected(bookingId);
      const selectedBooking = company.bookings.find(
        (b) => b.MotherBookingId === bookingId
      );
      setSelectedBooking(selectedBooking);
      setSelectedDate(
        new Date(`${selectedBooking.Date}T${selectedBooking.Start}`)
      );
      setSelectedFromTime(
        new Date(`${selectedBooking.Date}T${selectedBooking.Start}`)
      );
      setSelectedToTime(
        new Date(`${selectedBooking.Date}T${selectedBooking.End}`)
      );
    }
  };

  const handlePutBooking = async () => {
    let body = {
      motherbookingId: selectedBooking.MotherBookingId,
      date: selectedBooking.Date,
      start: selectedBooking.Start,
      end: selectedBooking.End,
      comment: selectedBooking.Comment,
    };

    const editRequest = await AuthService.fetch(
      `${AuthService.API_URL}/editmotherbooking`,
      {
        method: 'post',
        data: body,
      }
    );

    if (editRequest.data.status === 'Success') {
      snackbarshow(lang.editedMotherbookingSuccess);
    }
  };

  const handleDeleteBooking = async () => {
    let body = {
      BookingId: selected,
    };
    const cancelData = await AuthService.fetch(
      `${AuthService.API_URL}/cancelmotherbooking`,
      { method: 'post', data: body }
    );
    if (cancelData.data.status === 'Success') {
      setCompany({
        ...company,
        bookings: company.bookings.filter(
          (item) => item.MotherBookingId !== selected
        ),
      });
      setSelected(undefined);
      snackbarshow(lang.cancelledEmployeebookingSuccess);
    }
  };

  const openPhoneMenu = (e, row) => {
    if (e.target.type !== 'checkbox') {
      !e.target.parentNode.parentNode.classList.contains(
        `${generalClass.DropButtonActive}`
      )
        ? e.target.parentNode.parentNode.classList.add(
            `${generalClass.DropButtonActive}`,
            `${companyBookingClass.ContentHeight}`
          )
        : e.target.parentNode.parentNode.classList.remove(
            `${generalClass.DropButtonActive}`,
            `${companyBookingClass.ContentHeight}`
          );
    } else {
      handleSelect(row.MotherBookingId);
    }
  };

  const getMotherBookingEnd = (Date) => {
    const ThisBooking = createTimeslots(Date, company);
    const ThisBookingLength = ThisBooking.timeslots.length;

    return ThisBooking.timeslots[ThisBookingLength - 1].End;
  };

  const cancelBookingDialogProps = {
    handleDeleteBooking,
    selectedBooking,
    company,
    breakpoint,
    snackbarshow,
  };
  const changeBookingDialogProps = {
    handlePutBooking,
    selectedBooking,
    setSelectedBooking,
    breakpoint,
    selectedDate,
    setSelectedDate,
    selectedFromTime,
    setSelectedFromTime,
    selectedToTime,
    setSelectedToTime,
  };
  const requestBookingDialogProps = { company, breakpoint, snackbarshow };
  return (
    <React.Fragment>
      {breakpoint ? (
        <React.Fragment>
          <div className={generalClass.phoneContentWrap}>
            <div className={generalClass.headings}>
              {lang.individualCompanyTitle} ({company.bookings.length})
            </div>
            {company.bookings.length > 0 &&
              company.bookings.map((row, index) => (
                <div key={index + 'a'} className={generalClass.DropButton}>
                  <Button onClick={(e) => openPhoneMenu(e, row)}>
                    <span className={'DropButtonTitle'}>{row.Date}</span>
                    <ArrowDropDownIcon className={'SVGBut'}></ArrowDropDownIcon>
                  </Button>
                  <div className={generalClass.DropButtonContentHeight}>
                    <div>
                      <p>
                        {lang.bookings} {row.DaughterBookings.length}
                      </p>
                      <p>
                        {lang.timeframe} {row.Start} - {row.End}
                      </p>
                      <p>
                        {lang.type} {row.STypeName}
                      </p>
                      <p>
                        {lang.masseure} {row.Masseuse}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            <div
              className={`${companyBookingClass.requestBooking} ${generalClass.clickable}`}
            >
              <RequestBookingDialog {...requestBookingDialogProps} />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className={companyBookingClass.tableStyle}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={6}>
              <div>
                {lang.individualCompanyTitle} ({company.bookings.length})
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className={`${companyBookingClass.requestBooking} ${generalClass.clickable}`}
              >
                <RequestBookingDialog {...requestBookingDialogProps} />
              </div>
            </Grid>
          </Grid>
          {company.bookings.length > 0 ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>{lang.date}</StyledTableCell>
                    {company.hasEmployeeBookings && (
                      <StyledTableCell>{lang.bookings}</StyledTableCell>
                    )}
                    <StyledTableCell>{lang.timeframe}</StyledTableCell>
                    <StyledTableCell>{lang.type}</StyledTableCell>
                    <StyledTableCell>{lang.masseure}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {company.bookings.map((row, index) => (
                    <TableRow
                      className={generalClass.clickable}
                      key={index + 'b'}
                      value={row.MotherBookingId}
                      onClick={() => handleSelect(row.MotherBookingId)}
                    >
                      <StyledTableCell>{prettydate(row.Date)}</StyledTableCell>
                      {company.hasEmployeeBookings && (
                        <StyledTableCell>
                          {row.DaughterBookings.length}
                        </StyledTableCell>
                      )}
                      <StyledTableCell>
                        {row.Start} - {getMotherBookingEnd(row.Date)}
                      </StyledTableCell>
                      <StyledTableCell>{row.STypeName}</StyledTableCell>
                      <StyledTableCell>{row.Masseuse}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className={companyBookingClass.noBookings}>
              {lang.noAvailableCompanyBookings}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
