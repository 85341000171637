import { RRDate } from '@raskrask-dk/raskrask-utils';
export function createTimeslots(date, companyObject, selectedBooking) {
  var currentDate = new Date().getTime();

  var mother = companyObject.bookings.filter((el) => el.Date === date)[0];

  var duration = parseInt(mother.Duration);
  var employeeDuration = parseInt(companyObject.employeeBookingDuration);

  var nmbtimeslots = (duration / employeeDuration).toFixed(0);
  var start = mother.Start;
  var end = addMinutes(start, employeeDuration);
  var breakStart = companyObject.breakStartTime;
  var breakEnd;

  if (companyObject.breakStartTime !== null) {
    const selectedBookingServiceDate = `${selectedBooking.Date} ${selectedBooking.Start}:00`;
    const isBreakWithinServiceDuration = checkIfBreakTimeIsWithinService(
      selectedBookingServiceDate,
      companyObject.breakStartTime,
      selectedBooking.Duration
    );
    if (isBreakWithinServiceDuration) nmbtimeslots = nmbtimeslots - 1;
  }
  if (breakStart !== null) {
    breakStart = companyObject.breakStartTime.substring(0, 5);
    breakEnd = addMinutes(
      breakStart,
      employeeDuration * companyObject.breakTimeslots
    );
    nmbtimeslots = (
      parseInt(nmbtimeslots) + companyObject.breakTimeslots
    ).toFixed(0);
  }

  var timeslots = [];

  for (var i = 0; i < nmbtimeslots; i++) {
    var deadlineBoundary = new Date(`${date}T${start}:00`);
    deadlineBoundary = deadlineBoundary.setHours(
      deadlineBoundary.getHours() - companyObject.employeeBookingDeadline
    );

    if (!(breakStart !== null && breakStart <= start && end <= breakEnd)) {
      var slot = {
        MotherBookingId: mother.MotherBookingId,
        BookingId: '',
        CustomerId: '',
        Start: start,
        End: end,
        Date: date,
        Available: true,
        Open: true,
        Name: '',
        Email: '',
        Phone: '',
        Selected: false,
        deadlineBoundary: deadlineBoundary > currentDate,
      };
      timeslots.push(slot);
    } else {
      var breakSlot = {
        MotherBookingId: mother.MotherBookingId,
        BookingId: 'BREAK', // do not remove
        CustomerId: '',
        Start: start,
        End: end,
        Date: date,
        Available: false,
        Open: true,
        Name: 'BREAK',
        Email: '',
        Phone: '',
        Selected: false,
        deadlineBoundary: deadlineBoundary > currentDate,
      };
      timeslots.push(breakSlot);
    }

    start = end;
    end = addMinutes(end, employeeDuration);
  }

  var fullSessionInfo = {
    masseuse: mother.Masseuse,
    deadline: companyObject.employeeBookingDeadline,
    employeeDuration: employeeDuration,
    timeslots: checkAvailabilities(timeslots, mother, selectedBooking),
  };

  return fullSessionInfo;
}

const checkIfBreakTimeIsWithinService = (
  serviceDate,
  breakStartTime,
  durationExcBreak
) => {
  const serviceStart = new RRDate(serviceDate);
  const serviceEnd = new RRDate(serviceDate).plus({
    minutes: durationExcBreak,
  });
  const breakStart = new RRDate(serviceDate).set({
    hour: breakStartTime.substring(0, 2),
    minute: breakStartTime.substring(3, 5),
  });
  const breakEnd = breakStart.addMinutes(29);
  return !breakEnd.overlap({ from: serviceStart, to: serviceEnd });
};

function checkAvailabilities(timeslots, mother, selectedBooking) {
  timeslots.forEach((element) => {
    var overlapping = mother.DaughterBookings.filter(
      (m) => m.Start === element.Start
    );
    if (overlapping.length !== 0) {
      element.Available = false;
      element.Name = nameManipulation(overlapping[0].Name);
      element.BookingId = overlapping[0].BookingId;
      element.CustomerId = overlapping[0].CustomerId;
      element.Email = overlapping[0].Email;
      element.Phone = overlapping[0].Phone;

      if (selectedBooking !== undefined) {
        if (selectedBooking.BookingId === overlapping[0].BookingId) {
          element.Move = true;
        } else {
          element.Move = false;
        }
      }
    }
  });

  return timeslots;
}

function addMinutes(time, minutes) {
  var time = time.split(':');
  var m = parseInt(time[0]) * 60 + parseInt(time[1]);
  var newMinutes = m + minutes;
  var updatedTime =
    ('0' + Math.floor(newMinutes / 60).toString()).slice(-2) +
    ':' +
    ('0' + (newMinutes % 60).toString()).slice(-2);

  return updatedTime;
}

function nameManipulation(name) {
  var nameSplit = name.split(' ');

  var newName = nameSplit[0];

  for (var i = 1; i < nameSplit.length; i++) {
    newName += ` ${nameSplit[i].substring(0, 1)}. `;
  }

  return newName;
}
