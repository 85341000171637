import React, { useState, useContext } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { BookAppointmentView } from '../../../../bookappointment/book_appointment_view';
import { LanguageContext } from '../../../../../language_context';
import { generalStyle } from '../../../../../generalStyle';
import CloseIcon from '@material-ui/icons/Close';

export const ChangeBookingDialog = ({ company, employeeDetails, Admin, selectedBooking, getEmployee, getCompanyFull, handleRefreshData, breakpoint, handleSetNavIndex, snackbarshow }) => {
    const { lang } = useContext(LanguageContext);
    const [open, setOpen] = useState(false);
    const generalClass = generalStyle();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        handleRefreshData();
    };
    const dialogProps = { company, employeeDetails, Admin, handleClose, selectedBooking, getEmployee, getCompanyFull, breakpoint, handleSetNavIndex, snackbarshow };
    
    return (
        <div>
            <span onClick={handleClickOpen}>
                {lang.editBookingBtn}
            </span>
            <Dialog
                className={generalClass.dialogWrapFullscreen}
                open={open}
                maxWidth='md'
                fullWidth='true'
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                {breakpoint && <CloseIcon onClick={handleClose} className={generalClass.CloseIconFullscreen}/>}
                <DialogContent>
                    <BookAppointmentView  {...dialogProps} />
                </DialogContent>
            </Dialog>
        </div>
    );
}