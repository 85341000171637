import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../constants/colors';

export const BookForEmployeeStyle = makeStyles((theme) => ({
    formControl: {
        marginBottom: '12px',

        '& label.Mui-focused': {
            color: Colors.primaryColor,
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: Colors.primaryColor,
        },

    },

    rootForm: {
        flexGrow: 1,
        
        '& .MuiTextField-root': {
            '& label.Mui-focused': {
                color: Colors.primaryColor,
            },

            '& .MuiInput-underline:after': {
                borderBottomColor: Colors.primaryColor,
            },

            '& .MuiInput-underline.Mui-error:after': {
                borderBottomColor: Colors.error,
            }
        },

        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: Colors.primaryColor,
            },
        },
    },
}));