import React, { useState, useContext } from 'react';
import {
    Icon, Button, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import { removeEmployeeDialogStyle } from './remove_employee_dialog_style';
import { LanguageContext } from '../../../language_context';
import { generalStyle } from '../../../generalStyle';
import AuthService from '../../../services/auth.service';
import CloseIcon from '@material-ui/icons/Close';

export const RemoveEmployeeDialog = ({ selectedEmployee, breakpoint, snackbarshow }) => {
    const generalClass = generalStyle();
    const { lang } = useContext(LanguageContext);
    const removeEmployeeDialogClass = removeEmployeeDialogStyle();
    const [open, setOpen] = useState(false);
    const handleRemoveEmployee = () => {
        AuthService.fetch(`${AuthService.API_URL}/employee/${selectedEmployee.CustomerID}`, {method: "delete"})
        .then((res) => {
            snackbarshow(lang.deletedEmployeeSuccess)
        })
        .catch((err) =>{
            console.log(err);
        })
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={generalClass.BottomMenu}>
            <div className={removeEmployeeDialogClass.EditCancelBookingBtn}>
                {!selectedEmployee.breakpoint ? (
                    <span onClick={handleClickOpen}>
                        {lang.removeCompanyEmployeeBtn} 
                        <Icon className="fa fa-trash" />
                    </span>
                ) : (
                    <span onClick={handleClickOpen}>
                        {lang.removeCompanyEmployeeBtn}
                    </span>
                )}
                <Dialog
                    className={generalClass.dialogWrap}
                    maxWidth='xs'
                    fullWidth='true'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    {selectedEmployee.breakpoint && <CloseIcon className={generalClass.CloseIcon}/>}
                    <DialogTitle id="alert-dialog-title">{lang.removeCompanyEmployeeDialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>{selectedEmployee.Email}</div>
                            <div>{selectedEmployee.FullName}</div>
                            <div>{selectedEmployee.Phone}</div>
                        </DialogContentText>
                    </DialogContent>
                    {!selectedEmployee.breakpoint ? (
                        <DialogActions>
                            <Button onClick={handleClose}>
                                {lang.close}
                            </Button>
                            <button className={removeEmployeeDialogClass.removeBtn} onClick={() => { handleClose(); handleRemoveEmployee() }}>
                                {lang.removeCompanyEmployeeDialogAccept}
                            </button>
                        </DialogActions>
                    ) : (
                        <DialogActions>
                            <button className={removeEmployeeDialogClass.removeBtn} onClick={() => { handleClose(); handleRemoveEmployee() }}>
                                {lang.removeCompanyEmployeeDialogAccept}
                            </button>
                        </DialogActions>
                    )}
                </Dialog>
            </div>
        </div>
    );
}
