import { makeStyles } from '@material-ui/core/styles';
import { FormatBold } from '@material-ui/icons';
import { Colors } from '../../../constants/colors';

export const EmployeesBookingStyle = makeStyles((theme) => ({
    dropDownMobile: {
        margin: '14px 0',
    },

    actionBtn: {
        margin: '20px 0',
        display: 'inline-flex',
        alignItems: 'baseline',
        
        '& span': {
            color: Colors.primaryColor,
            fontSize: '16px',
            fontWeight: '600',
            cursor: 'pointer',
        },

        '& span:nth-child(2)': {
            marginRight: '32px',
        },

        '& span:nth-child(2n)': {
            marginLeft: '10px',
        }
    },

    disabledActionBtn: {
        margin: '20px 20px 20px 0',
        display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',

        '& span': {
            color: Colors.grey,
            fontSize: '16px',
            fontWeight: '600',
        },

        '& span:first-of-type': {
            marginRight: '10px',
        },
    },

    tableHeader: {
        fontSize: '16px',
        fontWeight: '600',
        //color: Colors.primaryWhite,
        color: Colors.primaryColor,
        marginRight: '12px',
        marginLeft: '12px',
    },

    sectionHeader: {
        borderBottom: '1px solid ' + Colors.grey,

        '& div:first-child': {
            cursor: 'pointer',

            '& span': {
                marginLeft: '12px',
                display: 'inline'
            }
        }
    },

    MobileTableCell: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '34px',
        background: Colors.primaryWhite,
        borderBottom: 'solid 1px #d4d4d4',
        padding: '0px',

        '& div:first-child': {
            display: 'flex',
            alignItems: 'center',

            '&>p': {
                padding: '0',
            },
        },
    },

    TransparentText: {
        opacity: '0.5'
    },

    DropButton: {
        WebkitBoxShadow: '0px 1px 2px 0px rgba(0,0,0,0.25)',
        mozBoxShadow: '0px 1px 2px 0px rgba(0,0,0,0.25)',
        boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.25)',
        borderBottom: 'solid 0.1px rgba(0,0,0,0.1)',
        // height: '42px',

        '& button': {
            backgroundColor: Colors.primaryWhite,
            width: '100%',
            height: '42px',
            padding: '0',

            '&:hover': {
                backgroundColor: Colors.primaryWhite,
            },

            '& span:first-child': {
                justifyContent: 'flex-start',
                height: '100%',
            },

            '& .SVGBut': {
                fill: Colors.primaryColor,
                position: 'absolute',
                margin: '0 12px',
                right: '0',
                pointerEvents: 'none',
            },

            '& .DropButtonTitle': {
                fontWeight: 'bold',
                color: Colors.primaryColor,
                paddingLeft: '12px',
                pointerEvents: 'none',
                height: 'max-content !important',
            },

            '& .CheckBox': {
            
            },
        },
        '&>div': {
            backgroundColor: Colors.primaryWhite,
            overflow: 'hidden',

            '&>div': {
                padding: '8px 0',

                '& p': {
                    color: 'grey',
                    padding: '0px 36px',
                    margin: 0,
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: '500',
                    fontSize: '0.875rem',
                    lineHeight: '1.75',
                },
            },
        },
    },

    sectionContent: {
        marginBottom: '22px',
    },

    mobileTableHeader: {
        background: Colors.primaryWhite,
        display: 'flex',
        justifyContent: 'space-between',

        '& p': {
            fontWeight: 'bold !important'
        }
    },

    DropButtonContent: {
        display: 'none',

        '& div:last-child': {
            display: 'flex',
            alignItems: 'center',
            border: 'none !important',
        },
    },
    
    ContentShow: {
        '&>div': {
            display: 'initial',
        },
    },

    available: {
        '& td:first-child': {
            color: Colors.primaryColor
        },

        '& td': {
            color: Colors.grey
        }
    },
}))
