import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { TimeSlotsStyle } from './time_slots_style';
import { MasseuseDialog } from '../../common/masseusedialog/masseuse_dialog';
import { retrieveTimeslots, ShrinkObject } from './time_slots_form';
import { BookingLimitDialog } from './bookinglimitdialog/booking_limit_dialog';
import { LanguageContext } from './../../../language_context';
import { TimeslotItem } from './timeslotitem/timeslot_item';
import prettydate from '../../common/commonfunctions';
import { generalStyle } from '../../../generalStyle';

export const TimeSlotsForm = ({
  formData,
  handleSetFormData,
  company,
  handleNextStep,
  selectedBooking,
  employeeDetails,
  breakpoint,
  Admin,
}) => {
  const timeSlotsClass = TimeSlotsStyle();
  const generalClass = generalStyle();
  const { lang } = useContext(LanguageContext);
  const [fullSessionInfo, setFullSessionInfo] = useState(undefined);
  const [companyObj, setCompanyObj] = useState(company);
  const [openBookingLimitDialog, setOpenBookingLimitDialog] = useState(false);

  const [dates, setDates] = useState(company.dates); // dates available
  const [activeStep, setActiveStep] = useState(-1); // date stepper

  useEffect(() => {
    //modify object
    setCompanyObj(ShrinkObject(companyObj, employeeDetails));
    var temptDates = dates.filter((d) => d.IsWithinDaysAhead);
    setDates(temptDates);

    // find the initial date where there are available timeslots.
    var index = 0;
    if (temptDates.length > 0) {
      var temptSessionInfo = retrieveTimeslots(
        temptDates[index].Date,
        companyObj,
        companyObj.bookings[index]
      );
      while (
        temptSessionInfo.timeslots.length === 0 &&
        temptDates[index] !== undefined
      ) {
        temptSessionInfo = retrieveTimeslots(
          temptDates[index].Date,
          companyObj,
          companyObj.bookings[index]
        );
        index += 1;
      }
      if (temptSessionInfo.timeslots.length === 0) {
        setActiveStep(0);
      } else {
        setActiveStep(
          dates.findIndex(
            (date) => temptSessionInfo.timeslots[0].Date === date.Date
          )
        );
      }
      setFullSessionInfo(temptSessionInfo);
    }
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setFullSessionInfo(
      retrieveTimeslots(
        companyObj.dates[activeStep + 1].Date,
        companyObj,
        companyObj.bookings[activeStep + 1]
      )
    );
    handleSetFormData(undefined);
  };

  // Previous step in the stepper
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setFullSessionInfo(
      retrieveTimeslots(
        companyObj.dates[activeStep - 1].Date,
        companyObj,
        companyObj.bookings[activeStep - 1]
      )
    );
    handleSetFormData(undefined);
  };

  const setSelectedItem = (item) => {
    //toggles the clicked timeslot
    var temptTimeslots = fullSessionInfo.timeslots;

    temptTimeslots = temptTimeslots.map((i) =>
      (i.Start === item.Start) & (i.Selected !== true)
        ? { ...i, Selected: true }
        : { ...i, Selected: false }
    );
    setFullSessionInfo({ ...fullSessionInfo, timeslots: temptTimeslots });
  };

  const handleBookingLimitDialog = () => {
    setOpenBookingLimitDialog(!openBookingLimitDialog);
  };

  // Checks that the use have not exceeded the limited amount of bookings
  const handleCheckLimitBookings = (item) => {
    if (item !== undefined) {
      var bookingMonth = new Date(item.Date).getMonth() + 1;
      var numberOfBookingsInSelectedMonth = employeeDetails.ActiveBookings.filter(
        (b) => new Date(b.Date).getMonth() + 1 === bookingMonth
      ).length;

      if (
        numberOfBookingsInSelectedMonth >= company.maxBookingPerEmployee &&
        company.maxBookingPerEmployee !== null
      ) {
        handleBookingLimitDialog();
      } else {
        setSelectedItem(item);
        handleSetFormData(item);
      }
    }
  };

  const responsive = (ScreenSize) => {
    if (breakpoint && ScreenSize === 'phone') {
      return 'inherit';
    } else if (!breakpoint && ScreenSize === 'desktop') {
      return 'inherit';
    } else {
      return 'none';
    }
  };

  const bookingLimitDialogProps = {
    openBookingLimitDialog,
    handleBookingLimitDialog,
  };

  return dates.length === 0 ? (
    <div>No bookings available</div>
  ) : fullSessionInfo !== undefined ? (
    <div className={generalClass.phoneContentWrap}>
      <div
        style={{ display: responsive('desktop') }}
        className={timeSlotsClass.bookingDeadline}
      >
        {lang.deadlinePartOne} {fullSessionInfo.deadline} {lang.deadlinePartTwo}
      </div>
      {/* <div className={timeSlotsClass.header}> */}
      <h2 className={timeSlotsClass.heading}>{lang.chooseTimeSlot}</h2>
      <div
        style={{ display: responsive('desktop') }}
        className={timeSlotsClass.underheading}
      >
        {fullSessionInfo.employeeDuration} {lang.massageWith}{' '}
        <MasseuseDialog {...fullSessionInfo} />
      </div>
      <div
        style={{ display: responsive('desktop'), textAlign: 'center' }}
        className={timeSlotsClass.bookingDeadline}
      >
        {fullSessionInfo.CompanySetting_Message}
      </div>
      {/* </div> */}
      <React.Fragment>
        <div className={timeSlotsClass.datePicker}>
          {activeStep > 0 ? (
            <div className={timeSlotsClass.cursor} onClick={handleBack}>
              <Icon className="fa fa-angle-left"></Icon>
            </div>
          ) : (
            <div
              className={`${timeSlotsClass.cursor} ${timeSlotsClass.inactive}`}
            >
              <Icon className="fa fa-angle-left"></Icon>
            </div>
          )}
          <span className={timeSlotsClass.currentDate}>
            {prettydate(dates[activeStep].Date)}
          </span>
          {activeStep !== dates.length - 1 ? (
            <div className={timeSlotsClass.cursor} onClick={handleNext}>
              <Icon className="fa fa-angle-right"></Icon>
            </div>
          ) : (
            <div
              className={`${timeSlotsClass.cursor} ${timeSlotsClass.inactive}`}
            >
              <Icon className="fa fa-angle-right"></Icon>
            </div>
          )}
        </div>
        <div
          style={{ display: responsive('phone') }}
          className={timeSlotsClass.underheading}
        >
          {fullSessionInfo.employeeDuration} {lang.massageWith}{' '}
          <MasseuseDialog {...fullSessionInfo} />
        </div>
        <div
          style={{ display: responsive('phone'), textAlign: 'center' }}
          className={timeSlotsClass.bookingDeadline}
        >
          {fullSessionInfo.CompanySetting_Message}
        </div>
        {openBookingLimitDialog && (
          <BookingLimitDialog {...bookingLimitDialogProps} />
        )}
        <div className={timeSlotsClass.container}>
          {fullSessionInfo.timeslots.length === 0 ? (
            <div>Booking deadline exceeded</div>
          ) : (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              {fullSessionInfo.timeslots.map((item, index) => (
                <TimeslotItem
                  key={index}
                  {...{
                    item,
                    handleCheckLimitBookings,
                    Admin,
                    company,
                    employeeDetails,
                  }}
                />
              ))}
            </Grid>
          )}
          {formData === undefined ? (
            <button disabled className={timeSlotsClass.bookBtn}>
              {lang.nextStep}
            </button>
          ) : (
            <button
              className={`${timeSlotsClass.bookBtn} ${timeSlotsClass.timeslotSelected}`}
              onClick={handleNextStep}
            >
              {lang.nextStep}
            </button>
          )}
        </div>
        <div
          style={{ display: responsive('phone'), textAlign: 'center' }}
          className={timeSlotsClass.bookingDeadline}
        >
          {lang.deadlinePartOne} {fullSessionInfo.deadline}{' '}
          {lang.deadlinePartTwo}
        </div>
      </React.Fragment>
    </div>
  ) : (
    <div>Loading timeslots</div>
  );
};
