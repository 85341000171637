import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../constants/colors';

export const ChangeBookingDialogStyle = makeStyles({
    closeBtn: {
        margin: '0 auto',
        color: Colors.primaryColor
    },
    
    changeBtn: {
        backgroundColor: Colors.primaryGreen,
        color: Colors.primaryColor,
        fontSize: '16px',
        fontWeight: '600',
        borderRadius: '5px',
        height: '36px',
        minWidth: '200px',
        border: 'none',
        cursor: 'pointer',
    }
});

