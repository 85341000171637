import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Colors } from '../../../constants/colors';
import { BreakPoints } from '../../../constants/breakpoints';
import Button from "@material-ui/core/Button";

export const TimeSlotsStyle = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        fontSize: '30px',
        fontWeight: '500',
        margin: '12px',
        lineHeight: '30px',

        [theme.breakpoints.down(BreakPoints.main)]: {
            margin: '24px 0 8px 0',
            fontSize: '24px',
        },
    },
    underheading: {
        margin: 0,
        textAlign: 'center',
        color: Colors.grey,

        [theme.breakpoints.down(BreakPoints.main)]: {
            margin: '12px 0',
        },

        '& div': {
            display: 'contents',

            '& span': {
                textDecoration: 'underline',
                color: Colors.primaryColor,
                cursor: 'pointer',
            }
        }
    },

    bookingDeadline: {
        textAlign: 'end',
    },

    selected: {
        backgroundColor: Colors.primaryColor + " !important",
        color: 'white !important',
    },

    notSelected: {
        // magic
    },

    move: {
        backgroundColor: "white !important",
        color: Colors.primaryColor + ' !important',
        border: '3px dashed ' + Colors.primaryColor + ' !important',
    },

    notMove: {
        // magic
    },

    bookBtn: {
        width: '100%',
        marginTop: '15px',
        backgroundColor: Colors.transparentGrey,
        color: Colors.primaryColor,
        height: '40px',
        borderRadius: '5px',
        border: '1px solid ' + Colors.lightGrey,
        fontWeight: '600',
        marginBottom: '12px',
    },

    timeslotSelected: {
        backgroundColor: Colors.primaryGreen + " !important",
        color: Colors.primaryColor + " !important",
        cursor: 'pointer',
    },

    currentDate: {
        color: Colors.primaryColor,
        fontSize: '20px',
    },

    cursor: {
        cursor: 'pointer',
        display: 'inline-block',
        padding: '20px',

        '& .fa': {
            fontSize: '24px',
            fontWeight: '600',
        }
    },

    inactive: {
        color: Colors.grey + ' !important',
        cursor: 'default',
    },

    datePicker: {
        textAlign: 'center',
        marginBottom: '12px',

        [theme.breakpoints.down(BreakPoints.main)]: {
            fontWeight: 'bold',
            marginBottom: '0px',

            '& div': {
                padding: '12px'
            },
        },

        '& div:first-child': {
            marginRight: '40px',
            color: Colors.primaryColor
        },

        '& div:last-child': {
            marginLeft: '40px',
            color: Colors.primaryColor
        },
    },

    container: {
        maxWidth: '500px',
        margin: '0 auto',
    },
}));

export const TimeSlotBtn = withStyles((theme) => ({
    root: {
        height: '40px',
        width: '100%',
        color: Colors.primaryColor,
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '1px solid ' + Colors.primaryColor,
        fontWeight: '600',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        textTransform: 'none',
        display: 'inline',

        '&:hover': {
            backgroundColor: Colors.lightGrey,
        },

        '&:disabled': {
            border: 'none',
        },
    }
}))(Button);