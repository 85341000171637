import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../constants/colors';


// BreakPoints.main

export const removeEmployeeDialogStyle = makeStyles((theme) => ({
    backbtnPhone: {
        display: 'none',
    },
}))