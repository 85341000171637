import { ChangeBookingDialog } from './changebookingdialog/change_booking_dialog';
import { CancelBookingDialog } from './cancelbookingdialog/cancel_booking_dialog';
import { BookingOverviewStyle } from '../booking_overview_style';
import { generalStyle } from '../../../../generalStyle';
import { DateTime } from 'luxon'

export const EmployeeActions = ({ selected, changeBookingDialogProps, cancelBookingDialogProps}) => {
    const generalClass = generalStyle();
    const bookingOverviewClass = BookingOverviewStyle();
    console.log(cancelBookingDialogProps);

    const CanCancel = () => {
        if(selected !== undefined){
            const selectedBooking = cancelBookingDialogProps.selectedBooking;
            const startTime = DateTime.fromFormat(`${selectedBooking.Date} ${selectedBooking.Start}`, "yyyy-MM-dd hh:mm", {zone: "Europe/Copenhagen"});
            let deadlineHours = cancelBookingDialogProps.company.employeeBookingDeadline;
            const now = DateTime.now().setZone("Europe/Copenhagen");
            const diff = startTime.diff(now, "hour");
            const canCancel =  diff.hours >= deadlineHours;
        
           return canCancel ? (
                <>
                <ChangeBookingDialog {...changeBookingDialogProps} />
                <CancelBookingDialog {...cancelBookingDialogProps} />
                </>
           ):<div></div>
        }
    }

    return (
        <div className={generalClass.BottomMenu}>
            {selected !== undefined ? <div className={bookingOverviewClass.EditCancelBookingBtn}>
                <CanCancel />
            </div> : <div />}
        </div>
    )
}
