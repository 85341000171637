import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Colors } from '../../../constants/colors';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

export const MasseuseDialogStyle = makeStyles((theme) => ({
    header: {
        textAlign: 'center',

        '& img': {
            borderRadius: '50%',
            marginBottom: '20px'
        },

        '& div': {
            color: Colors.primaryColor,
            fontSize: '18px',
            fontWeight: '600',
            marginBottom: '8px'
        },
    },
    therapistRating: {
        display: 'inline-flex',
        alignItems: 'baseline',

        '& .fa-star': {
            color: Colors.primaryColor,
            marginLeft: '4px',
        },

        '& span:last-child': {
            color: Colors.primaryColor,
            fontWeight: 'normal',
            marginLeft: '4px',
        }
    },

    education: {
        '& div:first-child': {
            fontSize: '14px',
            color: Colors.grey,
        },

        '& div:last-child': {
            color: Colors.primaryColor,
            fontSize: '16px',
            fontWeight: '600',
            textTransform: 'uppercase',
        }
    },

    bio: {
        '& div:first-child': {
            fontSize: '14px',
            color: Colors.grey,
        },

        '& div:last-child': {
            fontSize: '16px',
        }
    }

}));

export const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);