import React, { useState } from 'react';
import { TimeSlotsForm } from './timeslotsform/time_slots_form_view';
import { UserInfoForm } from './userinfoform/user_info_form';

const getStepContent = (step, props) => {
  switch (step) {
    case 0:
      return <TimeSlotsForm {...props} />;
    case 1:
      return <UserInfoForm {...props} />;
    default:
      return 'Unknown step';
  }
};

export const BookAppointmentView = ({
  company,
  Admin,
  employeeDetails,
  handleSetNavIndex,
  handleClose,
  selectedBooking,
  getEmployee,
  getCompanyFull,
  breakpoint,
  snackbarshow,
}) => {
  const CanBook = () => {
    if (company.vouchers) {
      try {
        const EmployeeClips = employeeDetails.EmployeeClips;
        const BruttoStartDate = company.BruttoDateStart;
        const BruttoEndDate = company.BruttoDateEnd;
        let countBookings = 0;
        employeeDetails.PreviousBookings.forEach((booking) => {
          let ServiceDate = booking.Date;
          if (ServiceDate >= BruttoStartDate && ServiceDate <= BruttoEndDate) {
            countBookings++;
          }
        });

        employeeDetails.ActiveBookings.forEach((booking) => {
          let ServiceDate = booking.Date;
          if (ServiceDate >= BruttoStartDate && ServiceDate <= BruttoEndDate) {
            countBookings++;
          }
        });
        if (countBookings >= EmployeeClips) {
          return false;
        }
      } catch (err) {
        return false;
      }
    }
    return true;
  };

  const defaultUserInfo = {
    FullName: employeeDetails.FullName,
    Phone: employeeDetails.Phone,
    Email: employeeDetails.Email,
    CustomerId: employeeDetails.CustomerId,
    Comment: '',
    TermsAccept: true,
    CoronaAccept: true,
    CanEmployeeBook: CanBook(),
  };

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState(undefined);
  const [userInfo, setUserInfo] = useState(defaultUserInfo);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSetUserInfoData = (e) => {
    if (e.target.name === 'CoronaAccept') {
      setUserInfo({ ...userInfo, CoronaAccept: e.target.checked });
    } else if (e.target.name === 'TermsAccept') {
      setUserInfo({ ...userInfo, TermsAccept: e.target.checked });
    } else {
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    }
  };

  const handleSetFormData = (e) => {
    if (formData === undefined) {
      setFormData(e);
    } else if (e === undefined) {
      setFormData(e);
    } else {
      if (e.Date === formData.Date && e.Start === formData.Start) {
        setFormData(undefined);
      } else {
        setFormData(e);
      }
    }
  };

  const props = {
    formData,
    handleSetFormData,
    company,
    employeeDetails,
    handleNextStep,
    handleBackStep,
    userInfo,
    handleSetUserInfoData,
    handleSetNavIndex,
    handleClose,
    selectedBooking,
    getEmployee,
    getCompanyFull,
    breakpoint,
    Admin,
    snackbarshow,
  };
  return getStepContent(activeStep, props);
};
