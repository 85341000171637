import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Header } from "./header";
import { BookAppointmentView } from "../bookappointment/book_appointment_view";
import { EmployeeBookingView } from "../employeebooking/employee_booking_view";
import { EmployeesBookingView } from "../employeesbooking/employees_booking_view";
import { CompanyEmployeesView } from "../companyemployees/company_employees_view";

import { homeStyle, StyledTab, StyledTabs } from "./home_style";
import { LanguageContext } from "../../language_context";
import { CompanyBookingView } from "../companybooking/company_booking_view";
import { AppBar, Box, useMediaQuery } from "@material-ui/core";
import { BreakPoints } from "../../constants/breakpoints";

export const HomeView = ({
  company,
  employeeDetails,
  setCompany,
  setEmployeeDetails,
  getEmployee,
  getCompanyFull,
  Admin,
  currentUser,
  snackbarshow,
  setLoading,
}) => {
  const [selectedTab, setselectedTab] = useState("bookTab");
  const { lang } = useContext(LanguageContext);
  const homeClass = homeStyle();

  const [value, setValue] = useState(0); // Tabbar menu
  const [open, setOpen] = useState(false); // state of language menu
  // responsive webstuff
  const [mobileTabbarState, setmobileTabbarState] = useState(false);
  const breakpoint = useMediaQuery(`(max-width: ${BreakPoints.main}px)`);

  //if objects are empty
  if (company === undefined || employeeDetails === undefined) {
    return <p>Loading</p>;
  }

  const handleLanguageClose = () => {
    setOpen(false);
  };

  const handleLanguageOpen = () => {
    setOpen(true);
  };

  const handleSetNavIndex = (currentBooking, newValue) => {
    setValue(newValue);
    tabMobileOrder(newValue);
  };

  const openPhoneMenu = () => {
    setmobileTabbarState(!mobileTabbarState);
    handleLanguageClose();
  };

  // phone stuff
  const tabMobileOrder = (selected) => {
    let newElement = document.getElementById(`simple-tab-${selected}`);
    setselectedTab(newElement.getAttribute("tab-name"));
    if (mobileTabbarState) {
      openPhoneMenu();
    }
  };

  const NavBarState = (mobile) => {
    if (mobile && mobileTabbarState) {
      return homeClass.tabBarPhoneOpen;
    } else if (mobile) {
      return homeClass.tabBarPhone;
    } else {
      if (mobileTabbarState) {
        setmobileTabbarState(false);
      }
      return homeClass.tabBar;
    }
  };

  const headerViewProps = {
    company,
    openPhoneMenu,
    handleLanguageClose,
    handleLanguageOpen,
    selectedTab,
    mobileTabbarState,
    setmobileTabbarState,
    breakpoint,
    open,
    snackbarshow,
  };
  const employeeBookingViewProps = {
    employeeDetails,
    setEmployeeDetails,
    company,
    setCompany,
    handleSetNavIndex,
    getCompanyFull,
    getEmployee,
    breakpoint,
    currentUser,
    snackbarshow,
    setLoading,
  };
  const bookAppointmentViewProps = {
    company,
    employeeDetails,
    Admin,
    handleSetNavIndex,
    getEmployee,
    getCompanyFull,
    breakpoint,
    snackbarshow,
  };
  const employeesBookingViewProps = {
    company,
    handleSetNavIndex,
    getCompanyFull,
    breakpoint,
    snackbarshow,
  };
  const companyBookingViewProps = {
    company,
    setCompany,
    breakpoint,
    snackbarshow,
  };
  const companyEmployeesProps = { company, breakpoint, snackbarshow };

  const TabContent = () => {
    return company.hasEmployeeBookings ? (
      Admin ? (
        <IsAdmin />
      ) : (
        <IsNotAdmin />
      )
    ) : (
      <NoEmployeeBookings />
    );
  };

  const IsAdmin = () => {
    return (
      <React.Fragment>
        <AppBar
          className={NavBarState(breakpoint)}
          position="static"
          id="AppBar"
          color="transparent"
        >
          <StyledTabs
            classes={{ root: homeClass.tabRoot }}
            value={value}
            onChange={handleSetNavIndex}
            aria-label="simple tabs example"
          >
            <StyledTab label={lang.bookTab} {...a11yProps(0, "bookTab")} />
            <StyledTab
              label={lang.myBookingsTab}
              {...a11yProps(1, "myBookingsTab")}
            />
            <StyledTab
              label={lang.employeesBookingTab}
              {...a11yProps(2, "employeesBookingTab")}
            />
            <StyledTab
              label={lang.companyEmployeesTab}
              {...a11yProps(3, "companyEmployeesTab")}
            />
            {/* <StyledTab label={lang.companyBookingTab} {...a11yProps(4, "companyBookingTab")} /> */}
          </StyledTabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <BookAppointmentView {...bookAppointmentViewProps} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EmployeeBookingView {...employeeBookingViewProps} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EmployeesBookingView {...employeesBookingViewProps} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CompanyEmployeesView {...companyEmployeesProps} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <CompanyBookingView {...companyBookingViewProps} />
        </TabPanel>
      </React.Fragment>
    );
  };

  const IsNotAdmin = () => {
    return (
      <React.Fragment>
        <AppBar
          className={NavBarState(breakpoint)}
          position="static"
          id="AppBar"
          color="transparent"
        >
          <StyledTabs
            classes={{ root: homeClass.tabRoot }}
            value={value}
            onChange={handleSetNavIndex}
            aria-label="simple tabs example"
          >
            <StyledTab label={lang.bookTab} {...a11yProps(0, "bookTab")} />
            <StyledTab
              label={lang.myBookingsTab}
              {...a11yProps(1, "myBookingsTab")}
            />
          </StyledTabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <BookAppointmentView {...bookAppointmentViewProps} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EmployeeBookingView {...employeeBookingViewProps} />
        </TabPanel>
      </React.Fragment>
    );
  };

  const NoEmployeeBookings = () => {
    return (
      <React.Fragment>
        <AppBar
          className={NavBarState(breakpoint)}
          position="static"
          id="AppBar"
          color="transparent"
        >
          <StyledTabs
            classes={{ root: homeClass.tabRoot }}
            value={value}
            onChange={handleSetNavIndex}
            aria-label="simple tabs example"
          >
            <StyledTab
              label={lang.employeesBookingTab}
              {...a11yProps(0, "employeesBookingTab")}
            />
          </StyledTabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <EmployeesBookingView {...employeesBookingViewProps} />
        </TabPanel>
      </React.Fragment>
    );
  };

  const TabPanel = (props) => {
    const homeClass = homeStyle();
    const { children, value, index, ...other } = props;

    return (
      <div
        className={homeClass.tabContent}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const a11yProps = (index, name) => {
    return {
      id: `simple-tab-${index}`,
      "tab-name": `${name}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <React.Fragment>
      <Header {...headerViewProps} />
      <TabContent />
    </React.Fragment>
  );
};
