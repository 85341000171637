import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import AuthService from "../../../../../services/auth.service";
import { LanguageContext } from "../../../../../language_context";
import { BookForEmployeeStyle } from "./book_for_employee_style";
import { generalStyle } from "../../../../../generalStyle";
import CloseIcon from "@material-ui/icons/Close";
import { cancelBookingDialogStyle } from "../../../../employeebooking/bookingoverview/employeeactions/cancelbookingdialog/cancel_booking_dialog_style";

export const BookForEmployee = ({
  company,
  selectedTimeslot,
  handleRefreshData,
  breakpoint,
  snackbarshow,
}) => {
  const generalClass = generalStyle();
  const bookForEmployeeClass = BookForEmployeeStyle();
  const cancelBookingDialogClass = cancelBookingDialogStyle();
  const { lang } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [comment, setComment] = useState("");
  const [antiSpam, setAntiSpam] = useState(false);

  useEffect(() => {
    const getEmployees = async () => {
      let dataResponse = await AuthService.fetch(
        `${AuthService.API_URL}/getemployeesforadminbook?Company_ID=${company.companyId}`
      );
      if (dataResponse.data !== undefined) {
        setEmployees(dataResponse.data);
        handleClose();
      }
    };

    getEmployees();
  }, []);

  const handleSetComment = (e) => {
    setComment(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetSelectedEmployee = (event) => {
    setSelectedEmployee(event.target.value);
  };

  const bookAppointment = async () => {
    if (!antiSpam) {
      setAntiSpam(true);
      var body = {
        Customer_ID: selectedEmployee.CustomerID,
        ServiceDate:
          selectedTimeslot.Date + "T" + selectedTimeslot.Start + ":00",
        ProblemDescription: comment,
        MotherBookingId: selectedTimeslot.MotherBookingId,
      };

      const bookData = await AuthService.fetch(
        `${AuthService.API_URL}/bookemployee`,
        {
          method: "post",
          data: body,
        }
      );

      if (bookData.data.status === "Success") {
        handleRefreshData();
        snackbarshow(lang.bookedEmployeebookingSuccess);
      } else {
        snackbarshow("Fejl, kontakt RaskRask");
      }
      setAntiSpam(false);
    }
  };

  return (
    <div>
      <span onClick={handleClickOpen}>{lang.bookForEmployeeBtn}</span>
      <Dialog
        className={generalClass.dialogWrap}
        maxWidth="xs"
        fullWidth="true"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {breakpoint && <CloseIcon className={generalClass.CloseIcon} />}
        <DialogTitle id="alert-dialog-title">
          {lang.bookForEmployeeDialogTitle}
        </DialogTitle>
        <DialogContent>
          <div className={bookForEmployeeClass.rootForm}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
            >
              <DialogContentText id="alert-dialog-description">
                <div>{selectedTimeslot.Date}</div>
                <div>
                  {selectedTimeslot.Start} - {selectedTimeslot.End}
                </div>
              </DialogContentText>

              <FormControl className={bookForEmployeeClass.formControl}>
                <InputLabel id="pick-employee-label">
                  {lang.bookForEmployeeSelectLabel}
                </InputLabel>
                <Select
                  native={breakpoint}
                  labelId="pick-employee"
                  id="pick-employee"
                  value={selectedEmployee}
                  onChange={handleSetSelectedEmployee}
                >
                  {breakpoint
                    ? employees.map((emp, index) => (
                        <option key={index} value={emp}>
                          {emp.FullName} - {emp.Email}
                        </option>
                      ))
                    : employees.map((emp, index) => (
                        <MenuItem key={index} value={emp}>
                          {emp.FullName} - {emp.Email}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                onChange={handleSetComment}
                name="Comment"
                label={lang.comment}
                multiline
                rows={5}
                value={comment}
                placeholder={lang.commentSuggestion}
                variant="outlined"
              />
            </Grid>
          </div>
        </DialogContent>
        {breakpoint ? (
          <DialogActions>
            <button
              className={cancelBookingDialogClass.cancelBtn}
              onClick={() => {
                bookAppointment();
              }}
            >
              {lang.bookForEmployeeAccept}
            </button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>{lang.close}</Button>
            <button
              className={cancelBookingDialogClass.cancelBtn}
              onClick={() => {
                bookAppointment();
              }}
            >
              {lang.bookForEmployeeAccept}
            </button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
