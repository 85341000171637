import { CompanyEmployeesStyle } from "./company_employees_style";
import React, { useState, useEffect, useContext } from "react";
import {
  generalStyle,
  StyledTableCell,
  GreenCheckBox,
} from "../../generalStyle";
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { LanguageContext } from "../../language_context";
import AuthService from "../../services/auth.service";
import { RemoveEmployeeDialog } from "./removeemployeedialog/remove_employee_dialog";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export const CompanyEmployeesView = ({ company, breakpoint, snackbarshow }) => {
  const CompanyEmployeesClass = CompanyEmployeesStyle();
  const { lang } = useContext(LanguageContext);
  const generalClass = generalStyle();
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [EmployeesExists, setEmployeesExists] = useState("none");
  const [AdminsExists, setAdminsExists] = useState("none");

  useEffect(() => {
    const getEmployees = async () => {
      let employeesData = await AuthService.fetch(
        `${AuthService.API_URL}/employees?Company_ID=${company.companyId}`
      );
      if (employeesData.data !== undefined) {
        setEmployees(employeesData.data);
      }
    };

    if (employees.length === 0) {
      getEmployees();
    }
  }, []);

  const handleSetSelectedEmployee = (employee) => {
    if (employee === selectedEmployee) {
      setSelectedEmployee(null);
    } else {
      setSelectedEmployee(employee);
    }
  };

  const openPhoneMenu = (e, row) => {
    if (e.target.type !== "checkbox") {
      !e.target.parentNode.parentNode.classList.contains(
        `${generalClass.DropButtonActive}`
      )
        ? e.target.parentNode.parentNode.classList.add(
            `${generalClass.DropButtonActive}`,
            `${CompanyEmployeesClass.ContentHeight}`
          )
        : e.target.parentNode.parentNode.classList.remove(
            `${generalClass.DropButtonActive}`,
            `${CompanyEmployeesClass.ContentHeight}`
          );
    } else {
      handleSetSelectedEmployee(row);
    }
  };

  const mobileProps = {
    ...selectedEmployee,
    breakpoint,
    snackbarshow,
  };
  console.log(employees);
  const EmployeesByRole = (prop) => {
    let { Role } = prop;
    return employees.map((row, index) => {
      if (row.Role === Role) {
        if (Role === "companyadmin") {
          setAdminsExists("initial");
        } else {
          setEmployeesExists("initial");
        }
        return (
          <div key={index + "c"} className={generalClass.DropButton}>
            <Button onClick={(e) => openPhoneMenu(e, row)}>
              <span className={"DropButtonTitle"}>{row.FullName}</span>
              <ArrowDropDownIcon className={"SVGBut"} />
            </Button>
            <div className={CompanyEmployeesClass.buttonContentWrap}>
              <div>
                <p>{row.Email}</p>
                <p>{row.Phone}</p>
              </div>
              {company.vouchers && (
                <div>
                  <p>{row.RemaningVouchers}</p>
                  <p>{lang.vouchersLeft}</p>
                </div>
              )}
            </div>
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div>
      {breakpoint ? (
        <div className={generalClass.phoneContentWrap}>
          <div style={{ display: AdminsExists }}>
            <div className={generalClass.headings}>Admin</div>
            <div className={CompanyEmployeesClass.buttonWrap}>
              <EmployeesByRole Role="companyadmin" />
            </div>
          </div>
          <div style={{ display: EmployeesExists }}>
            <div className={generalClass.headings}>
              Standard {lang.permission}
            </div>
            <div>
              <EmployeesByRole Role="Employee" />
            </div>
          </div>
          {selectedEmployee !== null && (
            <RemoveEmployeeDialog {...mobileProps} />
          )}
        </div>
      ) : (
        <div>
          {employees.length > 0 && (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{lang.email}</StyledTableCell>
                    <StyledTableCell>{lang.name}</StyledTableCell>
                    <StyledTableCell>{lang.phone}</StyledTableCell>
                    {company.vouchers && (
                      <StyledTableCell>{lang.vouchersUsed}</StyledTableCell>
                    )}
                    <StyledTableCell>{lang.permission}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employees
                    .sort((a, b) => {
                      let fa = a.FullName.toLowerCase(),
                        fb = b.FullName.toLowerCase();

                      if (fa < fb) {
                        return -1;
                      }
                      if (fa > fb) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((row) => (
                      <TableRow
                        className={generalClass.clickable}
                        key={row.CustomerID}
                        value={row.CustomerID}
                        onClick={() => handleSetSelectedEmployee(row)}
                      >
                        <StyledTableCell>{row.Email}</StyledTableCell>
                        <StyledTableCell>{row.FullName}</StyledTableCell>
                        <StyledTableCell>{row.Phone}</StyledTableCell>
                        {company.vouchers && (
                          <StyledTableCell>
                            {row.RemaningVouchers}/{row.TotalVouchers}
                          </StyledTableCell>
                        )}
                        <StyledTableCell>{row.Role}</StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      )}
    </div>
  );
};
