function prettydate(date, time){
        let day = date.substring(8, 10);
        let month = date.substring(5,7);
        let year = date.substring(2,4);
    if(time){
        return `${day}/${month}/${year} kl. ${time}`;        
    }else{
        return `${day}/${month}/${year}`;
    }
}

export default prettydate;