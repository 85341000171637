import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { requestBookingDialogStyle } from './request_booking_dialog_style';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import AuthService from '../../../services/auth.service';
import TextField from '@material-ui/core/TextField';
import { LanguageContext } from '../../../language_context';
import { generalStyle } from '../../../generalStyle';
import CloseIcon from '@material-ui/icons/Close';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

export const RequestBookingDialog = ({ company, breakpoint, snackbarshow }) => {
    const { lang } = useContext(LanguageContext);
    const requestBookingDialogClass = requestBookingDialogStyle();
    const generalClass = generalStyle();
    const [open, setOpen] = useState(false);

    const [selectedFromDateTime, setSelectedFromDateTime] = useState();
    const [selectedToDateTime, setSelectedToDateTime] = useState();
    const [selectedBreak, setSelectedBreak] = useState();
    const [deadlineClose, setDeadlineClose] = useState(12);
    const [comment, setComment] = useState();

    useEffect(() => {
        var initialDate = new Date();
        var numberOfDaysToAdd = 6;
        initialDate.setDate(initialDate.getDate() + numberOfDaysToAdd);
        var dd = String(initialDate.getDate()).padStart(2, '0');
        var mm = String(initialDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = initialDate.getFullYear();

        var fromTime = new Date(`${yyyy}-${mm}-${dd}T09:00:00`);
        var toTime = new Date(`${yyyy}-${mm}-${dd}T16:00:00`);
        var breakTime = new Date(`${yyyy}-${mm}-${dd}T12:00:00`);

        setSelectedFromDateTime(fromTime);
        setSelectedToDateTime(toTime);
        setSelectedBreak(breakTime);
        setComment('');
    }, [open]);

    const handleDeadlineClose = e => {
        setDeadlineClose(e.target.value);
    }

    const handleComment = e => {
        setComment(e.target.value);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getTimeFormat = (date) => {
        return `${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
    }

    const handleBookingRequest = async () => {
        let body = {
            date: `${selectedFromDateTime.getDate()}/${selectedFromDateTime.getMonth()+1}/${selectedFromDateTime.getFullYear()}`,
            start: getTimeFormat(selectedFromDateTime),
            end: getTimeFormat(selectedToDateTime),
            comment: comment,
            companyId: company.companyId
        };
        let bookingRequestData = await AuthService.fetch(`${AuthService.API_URL}/requestnewbooking`, {
            method: 'post',
            data: body
        });

        if(bookingRequestData.data.status === "Success") {
            setComment('');
            snackbarshow(lang.requestMotherbookingSuccess);
            handleClose();
        }
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>
                <Icon className="fa fa-plus" />
                <span>{lang.requestNewBookingBtn}</span>
            </Button>
            <Dialog
                className={generalClass.dialogWrapFullscreen}
                maxWidth='xs'
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                {breakpoint && <CloseIcon onClick={handleClose} className={generalClass.CloseIconFullscreen}/>}
                <DialogTitle id="alert-dialog-title">{lang.requestNewBookingTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center">
                                <KeyboardDatePicker
                                    fullWidth='true'
                                    margin="normal"
                                    id="date-picker-dialog-from"
                                    label={lang.pickDate}
                                    format="dd/MM/yyyy"
                                    value={selectedFromDateTime}
                                    onChange={setSelectedFromDateTime}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardTimePicker
                                    fullWidth='true'
                                    sm={2}
                                    margin="normal"
                                    id="time-picker-from"
                                    ampm={false}
                                    label={lang.from}
                                    value={selectedFromDateTime}
                                    onChange={setSelectedFromDateTime}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                                <KeyboardTimePicker
                                    fullWidth='true'
                                    margin="normal"
                                    id="time-picker-to"
                                    label={lang.to}
                                    ampm={false}
                                    value={selectedToDateTime}
                                    onChange={setSelectedToDateTime}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                                {/* <KeyboardTimePicker
                                    fullWidth='true'
                                    margin="normal"
                                    id="time-picker-break"
                                    ampm={false}
                                    label={lang.breakAt}
                                    value={selectedBreak}
                                    onChange={setSelectedBreak}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                /> */}
                                {/* <TextField
                                    fullWidth='true'
                                    id="standard-number"
                                    margin="normal"
                                    label={lang.bookingDeadline}
                                    type="number"
                                    value={deadlineClose}
                                    onChange={handleDeadlineClose}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /> */}
                                <TextField
                                    fullWidth='true'
                                    margin="normal"
                                    id="outlined-multiline-static-comment"
                                    className={requestBookingDialogClass.requestBookingComment}
                                    name="Comment"
                                    label={lang.comment}
                                    multiline
                                    rows={5}
                                    value={comment}
                                    onChange={handleComment}
                                    variant="outlined"
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </DialogContentText>
                </DialogContent>
                {breakpoint ? (
                    <DialogActions>
                        <button className={requestBookingDialogClass.requestBtn} onClick={() => { handleBookingRequest() }}>
                            {lang.makeRequestBtn}
                        </button>
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button onClick={handleClose}>
                            {lang.close}
                        </Button>
                        <button className={requestBookingDialogClass.requestBtn} onClick={() => { handleBookingRequest() }}>
                            {lang.makeRequestBtn}
                        </button>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
}
