import React from "react";
import ReactDOM from "react-dom";
import LanguageWrapper from "./languages/LanguageWrapper";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import * as serviceWorker from './serviceWorker';
// import WebFont from 'webfontloader';

// WebFont.load({
//     google: {
//         families: ['Source Sans Pro:300,400,400i,600,700'],
//         text: 'abcdefghijklmnopqrstuvwxyzæøåABCDEFGHIJKLMNOPQRSTUVWXYZÆØÅ1234567890'
//     }
// })

ReactDOM.render(<LanguageWrapper />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();