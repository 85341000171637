import { makeStyles, withStyles } from "@material-ui/core/styles";
import { BreakPoints } from './constants/breakpoints';
import { Colors } from './constants/colors';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

export const generalStyle = makeStyles((theme) => ({
    typography: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontSize: 16,
      fontWeight: 400,
    },
    
    headings: {
        color: '#276A82',
        fontSize: '16px',
        fontWeight: '600',
        marginBottom: '8px'
    },
        
    dialogWrapFullscreen: {
        '& .MuiDialog-container': {
            '& .MuiDialogActions-root': {
                padding: '16px 24px',
            }
        }
    },

    dialogWrap: {
        '& .MuiDialog-container': {
            '& .MuiDialogActions-root': {
                padding: '16px 24px',
            }
        }
    },

    // Dialog PHone
    [theme.breakpoints.down(BreakPoints.main)]: {
        CloseIcon: {
            top: '-46px',
            position: 'absolute',
            right: '-8px',
            fill: 'white',
            fontSize: '42px',
            pointerEvents: 'none'
        },
        
        dialogWrap: {
            '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                    overflow: 'unset'
                },

                '& .MuiDialogActions-root': {
                    justifyContent: 'center',
                    padding: '16px 24px',
                }
            }
        },

        CloseIconFullscreen: {
            top: '8px',
            position: 'absolute',
            right: '8px',
            fontSize: '48px',
        },

        dialogWrapFullscreen: {
            '& .MuiDialog-container': {
                height: 'auto',

                '& .MuiPaper-root': {
                    margin: '0',
                    height: '100vh',
                    width: '100vw',
                    maxWidth: '100%',
                    justifyContent: 'center',
                },

                '& .MuiDialogActions-root': {
                    justifyContent: 'center',
                },

                '& .MuiDialogContent-root': {
                    flex: 'inherit'
                }
            }
        },
    },

    // PhoneUi
    phoneContentWrap: {
        marginTop: '50px'
    },

    DropButton: {
        WebkitBoxShadow: '0px 1px 2px 0px rgba(0,0,0,0.25)',
        mozBoxShadow: '0px 1px 2px 0px rgba(0,0,0,0.25)',
        boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.25)',
        borderBottom: 'solid 0.1px rgba(0,0,0,0.1)',
        // height: '42px',
        '& button': {
            backgroundColor: Colors.primaryWhite,
            width: '100%',
            height: '42px',
            padding: '0',

            '&:hover': {
                backgroundColor: Colors.primaryWhite,
            },

            '& span:first-child': {
                justifyContent: 'flex-start',
                height: '100%',
            },

            '& .SVGBut': {
                fill: '#276A82',
                position: 'absolute',
                margin: '0 12px',
                right: '0',
                pointerEvents: 'none',
            },

            '& .DropButtonTitle': {
                fontWeight: 'bold',
                pointerEvents: 'none',
                height: 'max-content !important',
            },

            '& .CheckBox': {
            
            },
        },

        '&>div': {
            backgroundColor: Colors.primaryWhite,
            height: '0px',
            overflow: 'hidden',
            transition: '0.4s',
            
            '&>div': {
                padding: '8px 0',

                '& p': {
                    color: 'grey',
                    padding: '4px 36px',
                    margin: 0,
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: '500',
                    fontSize: '0.875rem',
                    lineHeight: '1.75',
                },
            },
        },
    },

    DropButtonActive: {
        '& button': {
            backgroundColor: '#E9F0F3 !important',
        },
    },

    BottomMenu: {
        [theme.breakpoints.down(BreakPoints.main)]: {
            position: 'fixed',
            height: 'max-content',
            width: 'max-content',
            bottom: 0,
            left: 0,
            backgroundColor: Colors.lightGrey,
            zIndex: 999
        },
        '&>div': {
            [theme.breakpoints.down(BreakPoints.main)]: {
                width: '100vw',
                height: 'max-content',
                justifyContent: 'center',
                borderTop: '1px solid #EEE',
                padding: '10px',
                '&>div': {
                    margin: '24px 20px',
                }
            },

            height: '100%',
            width: '100%',
            display: 'flex',
            paddingTop: '24px',

            '&>div': {
                marginRight: '24px',
                width: 'max-content',

                '&>span': {
                    display: 'flex',
                    color: '#276A82',
                    fontSize: '16px',
                    fontWeight: '600',
                    cursor: 'pointer',
                    '&>span': {
                        fontSize: '20px',
                        fontWeight: '100',
                        padding: '4px 8px',
                        width: 'max-content',
                        height: '100%',
                    },
                },
            },
        },
    },

    bgColor: {
        color: 'black'
    },

    clickable: {
        cursor: 'pointer',
    },

    WrapTest: {

    },

    AppBar: {
        position: 'fixed',
        backgroundColor: '#276A82 !important',
        boxShadow: '0 0 black',
    },

    header: {
        justifyContent: 'space-between',

        '& p': {
            width: 'max-content !important',
        },

        '& img': {
            maxWidth: "180px",
        },

        [theme.breakpoints.down(BreakPoints.main)]: {
            height: '50px',

            '& img': {
                maxWidth: "125px",
            },
          },
    },

    wrapper: {
        width: '100%',
        maxWidth: '1460px',
        margin: '0 auto'
    },

    companyLogo: {
        maxWidth: "180px",
    },

    underheader: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '12px',

        '& div': {
            fontSize: '14px',
            color: '#276A82',
            border: 'none',
        },

        [theme.breakpoints.down(BreakPoints.main)]: {
            position: 'absolute',
            padding: '8px',
            right: '0px',
        }
    },

    languagePickerOption: {
        width: '100px',
        textAlign: 'center',
        cursor: 'pointer',
        padding: '4px',
        border: 'none !important',
    },

    signOut: {
        fontSize: '14px',
        color: 'white',
    },

    // <CHECKBOX
    root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
    },

      icon: {
        borderRadius: 3,
        width: 18,
        height: 18,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: Colors.primaryWhite,

        '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        // 'input:hover ~ &': {
        //   backgroundColor: '#ebf1f5',
        // },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
        },
      },

      checkedIcon: {
        backgroundColor: Colors.primaryGreen,

        '&:before': {
          display: 'block',
          width: 18,
          height: 18,
          backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
          content: '""',
        },
      },

}));

export const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: '16px',
        fontWeight: '600',
    },

    body: {
        fontWeight: '300',
    },
}))(TableCell);

export const GreenCheckBox = (props) => {
    const classes = generalStyle();
    return (
        <Checkbox
            className={classes.root}
            color="default"
            checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}
