
import React, { useState, useContext } from 'react';
import {
    Grid, TextField, Button, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import { LanguageContext } from '../../../../../language_context';
import { RequestStyle } from './request_style';
import { generalStyle } from '../../../../../generalStyle';
import AuthService from '../../../../../services/auth.service';
import { cancelBookingDialogStyle } from '../../../../employeebooking/bookingoverview/employeeactions/cancelbookingdialog/cancel_booking_dialog_style';


function DecideRequest(selectedBookings, selectedTimeslot) {
    const { lang } = useContext(LanguageContext);
    if (selectedBookings.length > 0) {
        return (
            selectedBookings.map((booking, index) =>
                <div key={index}>
                    <div>{booking.Email}</div>
                    <div>{booking.Name}</div>
                    <div>{booking.Phone}</div>
                    <div>{booking.Date}</div>
                    <div>{booking.Start} - {booking.End}</div>
                    <br />
                </div>
            )
        );
    } else if (selectedTimeslot !== null) {
        return (
            <div>
                <div><b>{lang.date}:</b> {selectedTimeslot.Date}</div>
                <div><b>{lang.point_in_time}:</b> {selectedTimeslot.Start} - {selectedTimeslot.End}</div>
            </div>
        );
    }
}

export const RequestDialog = ({ selectedBookings, selectedTimeslot, snackbarshow }) => {
    const generalClass = generalStyle();
    const bookForEmployeeClass = RequestStyle()
    const cancelBookingDialogClass = cancelBookingDialogStyle();
    const { lang } = useContext(LanguageContext);
    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState('');

    const handleSetComment = e => {
        setComment(e.target.value);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const postRequest = async () => {
        let body = {
            bookings: JSON.stringify(selectedBookings),
            timeslot: JSON.stringify(selectedTimeslot),
            webhook: "adminrequestafterdeadline",
            comment: comment
        }

        const requestData = await AuthService.fetch(`${AuthService.API_URL}/postrequest`,{method: 'post', data: body});

        if(requestData.data.status === 'Success'){
            handleClose();
            snackbarshow(lang.requestSentSuccess);
        }
    }

    return (
        <div>
            <span onClick={handleClickOpen}>
                {lang.requestWhateverDialogBtn}
            </span>
            <Dialog
                className={generalClass.dialogWrapFullscreen}
                maxWidth='xs'
                fullWidth='true'
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{lang.requestWhateverEmployeeDialogTitle}</DialogTitle>
                <DialogContent>
                    <div className={bookForEmployeeClass.rootForm}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="stretch">
                            <DialogContentText id="alert-dialog-description">
                                <div>
                                    {lang.requestWhateverDialogDescription}
                                </div>
                                <br />
                                {DecideRequest(selectedBookings, selectedTimeslot)}
                                <br />
                                {lang.requestWhateverDialogDescriptionComment}
                            </DialogContentText>
                            <TextField
                                fullWidth
                                onChange={handleSetComment}
                                name="Comment"
                                label={lang.requestWhateverEmployeeDialogPlaceholder}
                                multiline
                                rows={5}
                                value={comment}
                                placeholder={lang.commentSuggestion}
                                variant="outlined"
                            />
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {lang.close}
                    </Button>
                    <button className={cancelBookingDialogClass.cancelBtn} onClick={() => { postRequest() }}>
                        {lang.requestWhateverDialogAcceptBtn}
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
