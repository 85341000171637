import React, { useState, useContext } from 'react';
import { generalStyle } from '../../../../generalStyle';
import { EmployeesBookingStyle } from '../employees_booking_style';
import { LanguageContext } from '../../../../language_context';
import { CancelEmployeeBookingDialog } from './cancelemployeebookingdialog/cancel_employee_booking_dialog';
import { SwapEmployeesDialog } from './swapEmployeesDialog/swap_employees_dialog';
import { BookForEmployee } from './bookforemployeedialog/book_for_employee_dialog';
import { Icon, Popover, Typography } from '@material-ui/core';
import { ChangeBookingDialog } from './changebookingdialog/change_booking_dialog';
import { RequestDialog } from './requestdialog/request_dialog';

export const AdminActions = ({
  company,
  employeeDetails,
  handleSetNavIndex,
  selectedBookings,
  selectedTimeslot,
  getCompanyFull,
  handleRefreshData,
  breakpoint,
  snackbarshow,
}) => {
  const { lang } = useContext(LanguageContext);
  const generalClass = generalStyle();
  const employeesBookingClass = EmployeesBookingStyle();

  const [popBookforEmp, setPopBookforEmp] = useState(null);
  const popBookforEmpOpen = Boolean(popBookforEmp);
  const popBookforEmpId = popBookforEmpOpen
    ? 'popBookforEmp-popover'
    : undefined;

  const [popSwap, setPopSwap] = useState(null);
  const popSwapOpen = Boolean(popSwap);
  const popSwapId = popSwapOpen ? 'popSwap-popover' : undefined;

  const [popEdit, setPopEdit] = useState(null);
  const popEditOpen = Boolean(popEdit);
  const popEditId = popEditOpen ? 'popEdit-popover' : undefined;

  const [popRequest, setPopRequest] = useState(null);
  const popRequestOpen = Boolean(popRequest);
  const popRequestId = popRequestOpen ? 'popRequest-popover' : undefined;

  const handleOpenSetPopRequest = (event) => {
    setPopRequest(event.currentTarget);
  };

  const handleOpenSetPopBookforEmp = (event) => {
    setPopBookforEmp(event.currentTarget);
  };

  const handleOpenSetPopSwap = (event) => {
    setPopSwap(event.currentTarget);
  };

  const handleOpenSetPopEdit = (event) => {
    setPopEdit(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopBookforEmp(null);
    setPopSwap(null);
    setPopEdit(null);
    setPopRequest(null);
  };

  var selectedBooking = selectedBookings[0];

  const changeBookingDialogProps = {
    company,
    employeeDetails,
    handleSetNavIndex,
    selectedBooking,
    getCompanyFull,
    handleRefreshData,
    snackbarshow,
  };

  const requstWhateverDialogProps = {
    selectedBookings,
    selectedTimeslot,
    snackbarshow,
  };

  return (
    <div>
      {selectedTimeslot !== null ? (
        <div className={employeesBookingClass.actionBtn}>
          <span>
            <BookForEmployee
              {...{
                company,
                selectedTimeslot,
                handleRefreshData,
                snackbarshow,
              }}
            />
          </span>
          <Icon className="fa fa-calendar" />
        </div>
      ) : (
        <div className={employeesBookingClass.disabledActionBtn}>
          <span
            aria-describedby={popBookforEmpId}
            onClick={handleOpenSetPopBookforEmp}
          >
            {lang.bookForEmployeeBtn}
          </span>
          <Icon className="fa fa-calendar" />
          <Popover
            id={popBookforEmpId}
            open={popBookforEmpOpen}
            anchorEl={popBookforEmp}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography className={generalClass.popContent}>
              {lang.bookDescription}
            </Typography>
          </Popover>
        </div>
      )}

      {
        selectedBookings[0] &&
          selectedBookings[0].deadlineBoundary &&
          selectedBookings.length === 2 ? (
          <div className={employeesBookingClass.actionBtn}>
            <span>
              <SwapEmployeesDialog
                {...{ selectedBookings, handleRefreshData, snackbarshow }}
              />
            </span>
            <Icon className="fa fa-retweet" />
          </div>
        ) : (
          <div className={employeesBookingClass.disabledActionBtn}>
            <span aria-describedby={popSwapId} onClick={handleOpenSetPopSwap}>
              {lang.swapEmployeesBtn}
            </span>
            <Icon className="fa fa-retweet" />
            <Popover
              id={popSwapId}
              open={popSwapOpen}
              anchorEl={popSwap}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography className={generalClass.popContent}>
                {lang.swapDescription}
              </Typography>
            </Popover>
          </div>
        )}

      {selectedBookings.length === 1 &&
        selectedBookings[0].deadlineBoundary &&
        company.vouchers === false ? (
        <div className={employeesBookingClass.actionBtn}>
          <span>
            <ChangeBookingDialog {...changeBookingDialogProps} />
          </span>
          <Icon className="fa fa-trash" />
        </div>
      ) : (
        <div className={employeesBookingClass.disabledActionBtn}>
          <span
            aria-describedby={popBookforEmpId}
            onClick={handleOpenSetPopEdit}
          >
            {lang.editBookingBtn}
          </span>
          <Icon className="fa fa-edit" />
          <Popover
            id={popEditId}
            open={popEditOpen}
            anchorEl={popEdit}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography className={generalClass.popContent}>
              {lang.editDescription}
            </Typography>
          </Popover>
        </div>
      )}

      {selectedBookings.length > 0 ? (
        <div className={employeesBookingClass.actionBtn}>
          <span>
            <CancelEmployeeBookingDialog
              {...{
                selectedBookings,
                handleRefreshData,
                snackbarshow,
                breakpoint,
              }}
            />
          </span>
          <Icon className="fa fa-trash" />
        </div>
      ) : (
        <div className={employeesBookingClass.disabledActionBtn}>
          <span>{lang.removeEmployeesBookingBtn}</span>
          <Icon className="fa fa-trash" />
        </div>
      )}

      {selectedBookings.length > 0 || selectedTimeslot !== null ? (
        <div className={employeesBookingClass.actionBtn}>
          <span>
            <RequestDialog {...requstWhateverDialogProps} />
          </span>
          <Icon className="fa fa-paper-plane" />
        </div>
      ) : (
        <div className={employeesBookingClass.disabledActionBtn}>
          <span
            aria-describedby={popRequestId}
            onClick={handleOpenSetPopRequest}
          >
            {lang.requestWhateverDialogBtn}
          </span>
          <Icon className="fa fa-paper-plane" />
          <Popover
            id={popRequestId}
            open={popRequestOpen}
            anchorEl={popRequest}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography className={generalClass.popContent}>
              {lang.requestDescription}
            </Typography>
          </Popover>
        </div>
      )}
    </div>
  );
};
