import React, { useContext } from 'react';
import { generalStyle, StyledTableCell, GreenCheckBox } from '../../../../generalStyle';
import { TableRow } from '@material-ui/core';
import { EmployeesBookingStyle } from '../employees_booking_style';
import { LanguageContext } from '../../../../language_context';

export const TimeslotRow = ({ row, selectedTimeslot, selectedBookings, handleSetSelectedBookings, handleSetSelectedTimeslot, breakpoint }) => {
    const { lang } = useContext(LanguageContext);
    const generalClass = generalStyle();
    const employeesBookingClass = EmployeesBookingStyle();

    if (row.BookingId === 'BREAK') {
        if (breakpoint) {
            return (
                <div className={employeesBookingClass.MobileTableCell}>
                    <div>
                        <GreenCheckBox disabled/>
                        <p>{row.Name}</p>
                    </div>
                    <div>
                        <p>{row.Start} - {row.End}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <TableRow
                    key={row.BookingId}
                    value={row.BookingId}>
    
                    <StyledTableCell align="left" padding="checkbox">
                        <GreenCheckBox
                            disabled
                        />
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.Name}</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                    <StyledTableCell align="right">{row.Start} - {row.End}</StyledTableCell>
                </TableRow>
            )
        }
    } else if (row.BookingId !== "") {
        if (breakpoint) {
            return (
                <div onClick={() => handleSetSelectedBookings(row)} className={employeesBookingClass.MobileTableCell}>
                    <div>
                        <GreenCheckBox checked={selectedBookings.includes(row)}/>
                        <p>{row.Name}</p>
                    </div>
                    <div>
                        <p>{row.Start} - {row.End}</p>
                    </div>
                </div>
            )
        } else {
        return (
            <TableRow className={generalClass.clickable}
                key={row.BookingId}
                value={row.BookingId}
                onClick={() => handleSetSelectedBookings(row)}>
                <StyledTableCell align="left" padding="checkbox">
                    <GreenCheckBox
                        checked={selectedBookings.includes(row)}
                    />
                </StyledTableCell>
                <StyledTableCell align="left">{row.Name}</StyledTableCell>
                <StyledTableCell align="center">{row.Email}</StyledTableCell>
                <StyledTableCell align="center">{row.Phone}</StyledTableCell>
                <StyledTableCell align="right">{row.Start} - {row.End}</StyledTableCell>
            </TableRow>
            )
        }
    } else {
        if (breakpoint) {
            return (
                <div onClick={() => handleSetSelectedTimeslot(row)} className={employeesBookingClass.MobileTableCell}>
                    <div>
                        <GreenCheckBox checked={selectedTimeslot === row}/>
                        <p className={employeesBookingClass.TransparentText}>{lang.availableTimeslot}</p>
                    </div>
                    <div>
                        <p>{row.Start} - {row.End}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <TableRow className={`${employeesBookingClass.available} ${generalClass.clickable}`}
                    key={row.Start}
                    value={row.Start}
                    onClick={() => handleSetSelectedTimeslot(row)}>
                    <StyledTableCell align="left" padding="checkbox">
                        <GreenCheckBox
                            checked={selectedTimeslot === row}
                        />
                    </StyledTableCell>
                    <StyledTableCell align="left">{lang.availableTimeslot}</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                    <StyledTableCell align="right">{row.Start} - {row.End}</StyledTableCell>
                </TableRow>
            )
        }
    }
}
