import React, { useState, useContext } from "react";
import {AppBar, FormControl, Select, Toolbar, Button} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LanguageContext, languages } from '../../language_context';
import { homeStyle } from "./home_style";
import { generalStyle } from "../../generalStyle";
import AuthService from "../../services/auth.service";

export const Header = ({company, openPhoneMenu, handleLanguageClose, handleLanguageOpen, selectedTab, mobileTabbarState, setmobileTabbarState, breakpoint, open}) => {
  const generalClass = generalStyle();
  const homeClass = homeStyle();
  const [language, setLanguage] = useState('Danish'); // language set
  const { lang, setLang } = useContext(LanguageContext);

  const handleLogout = (e) => {
    e.preventDefault();
    AuthService.logout();
    window.location.reload(false);
  }

  const handleLanguageChange = (event) => {
      var newLang = event.target.value;
    
      switch (newLang) {
        case 'English':
          setLang(languages.EN)
          break;
        case 'Danish':
          setLang(languages.DK)
          break;
        default:
          setLang(languages.DK)
          break;
      }
    
      setLanguage(newLang);
  };
    
  const setOverlay = (show) => {
    if (show) {
      return <div onClick={openPhoneMenu} className={homeClass.overlay}></div>
    } else {
      return "";
    }
  }
    
  const setButtonState = (state) => {
    if (state) {
      return `${homeClass.MobileNavBut} ${homeClass.MobileNavButActive}`
    } else {
      return `${homeClass.MobileNavBut}`
    }
  }

  return (
    <div>
      <AppBar position="relative" className={generalClass.AppBar} >
        <Toolbar className={generalClass.header}>
        <img src='https://www.raskrask.dk/wp-content/uploads/2020/09/rr-logo-white.png'alt={company.Name + " logo"} />
        {/* <Typography className={homeClass.logOutBut} onClick={signout}>{lang.signOut}</Typography> */}
        </Toolbar>
      </AppBar>

      <Button onClick={openPhoneMenu} className={setButtonState(mobileTabbarState)} id="ActiveTabNewParent">
          {lang[selectedTab]}
          <ExpandMoreIcon className={"SVGBut"}></ExpandMoreIcon>
      </Button>

      <div className={generalClass.underheader}>
        <div className={generalClass.languagePicker}>
          <FormControl>
            <Select
                native={breakpoint}
                disableUnderline
                labelId="language-controlled-open-select-label"
                id="language-controlled-open-select"
                open={open}
                onClose={handleLanguageClose}
                onOpen={handleLanguageOpen}
                value={language}
                onChange={handleLanguageChange}>
              <option className={generalClass.languagePickerOption} value='English'>{lang.englishLang}</option>
              <option className={generalClass.languagePickerOption} value='Danish'>{lang.danishLang}</option>
            </Select>
          </FormControl>
        </div>
        <div>
          <button className="btn save-btn" onClick={handleLogout} type="submit" disabled={false} >{lang.signOut}</button>
        </div>
      </div>

      <div className={homeClass.personalCompanyHeader}>
        <span>{lang.headlinePartOne}</span>{lang.headlinePartTwo}{company.name}
      </div>

      {setOverlay(mobileTabbarState)}
    </div>
  )
}