import React from 'react';

export const languages = {
  EN: {
    /**
     * Global
     */
    date: 'Date',
    name: 'Name',
    full_name: 'Full name',
    time: 'Time',
    type: 'Type',
    masseure: 'Therapists',
    close: 'Close',
    with: 'with',
    noAvailableCompanyBookings: 'No available company bookings',
    fullname: 'FullName',
    phone: 'Phone',
    email: 'Email',
    comment: 'Comment',
    from: 'from',
    to: 'to',
    pickDate: 'Pick Date',
    point_in_time: 'Time',
    service: 'Service',
    yes: 'Yes',
    no: 'No',
    company_name: 'Company Name',
    email_domain: 'Email Domain',
    mobile_number: 'Mobile number:',
    /**
     * Login
     */
    /* Login */
    login_email_text: 'Enter your work email',
    login_email_warning: 'Please enter a valid email address',
    login_password_warning: 'Must be at least 4 characters',
    login_cname_warning: 'Please fill in your name',
    login_cphone_warning: 'Please fill in your phone number',

    login_access_denied:
      'Unfortunately we could not recognise your email. You can attempt to reopen this page and retry with your workemail',
    login_access_denied_linktext: 'Click here to try again',
    login_choose_department: 'Choose branch',
    login_sign_up: 'Sign up',
    login_password: 'Password',
    login_not_recognised_domain: `
    Vi couldn't recognise your emaildomain.  
    Is your company a part fo a shared office space?`,
    login_create_password: 'Please create a password',
    login_logging_in: 'Logging in...',
    login_create_user_header: 'Create yourself as an employee at',

    login_create_user_phone_number: 'Private mobilenumber with MobilePay:',
    login_create_user_phone_number_explanation: `We will send confirmations and reminders to this number.`,
    login_please_fill_all_fields: 'Please fill out all fields',
    /**
     * Text outside the tabs, e.g. top bar
     */
    headlinePartOne: 'Company ',
    headlinePartTwo: 'massage for ',
    chooseTimeSlot: 'Choose a TimeSlot',
    signOut: 'Sign out',
    danishLang: 'Danish',
    englishLang: 'English',

    /**
     * Menu/tabs
     */
    bookTab: 'Book',
    myBookingsTab: 'My Bookings',
    employeesBookingTab: 'Bookings',
    companyBookingTab: 'Company Booking',
    companyEmployeesTab: 'Employees',

    /**
     * Book tab, timeslots overview
     */
    nextStep: 'Next step',
    massageWith: 'minute massage session with',
    deadlinePartOne: 'Book',
    deadlinePartTwo: 'hours before',

    /**
     * Book tab, employee form
     */
    yourDetails: 'Your Details',
    commentSuggestion: 'E.g. sore neck',
    acceptTermAndPrivacy: 'I accept the terms of trade & the privacy policy',
    acceptNoConvidSymptoms:
      'I confirm that I do not have any COVID-19 symptoms',
    bookBtn: 'Book',
    backBtn: 'Back',

    /**
     * Book tab, timeslots overview, therapists dialog
     */
    education: 'Education',
    bio: 'Bio',

    /**
     * My bookings tab
     */
    activeBooking: 'Active bookings',
    bookingHistory: 'Booking history',
    show: 'Show',
    hide: 'Hide',
    editBookingBtn: 'Edit booking',
    cancelBookingBtn: 'Cancel booking',
    noBookings: 'You do not have any booking',

    /**
     * My bookings tab, cancel dialog
     */
    cancelBookingDialogTitle: 'Cancel booking',
    cancelBookingDeadlineOne: 'it cost much money to cancel',
    cancelBookingDeadlineTwo: 'it cost little bit money to cancel',
    cancelBookingDeadlineThree:
      'Are you certain that you wish to cancel the booking?',
    cancelBookingDialogText:
      'Your should be aware of bla bla bla bla bla bla bla...',
    cancelBookingDialogDetails: 'Your are about to cancel your booking on:',
    cancelBookingDialogCancelBtn: 'Cancel booking',

    /**
     * Employees booking tab
     */
    availableBookings: 'Booked',
    removeEmployeesBooking: 'Remove employee',
    availableTimeslot: 'Available',
    noTimeSlotsCreated: 'No timeslots created',
    fullSession: 'Full session',
    swapDescription: 'Select two employees to swap',
    bookDescription: 'Select a Available timeslot to book',
    editDescription: 'Select one employee to change booking',
    requestDescription:
      'Send us a request if the selected timeslot/bookings is after the booking deadline.',

    /**
     * Employees booking tab, remove employee dialog
     */
    removeEmployeesBookingBtn: "Cancel employee's booking",
    removeEmployeeDialogTitle: 'Remove employee from timeslot?',
    removeEmployeeDialogRemoveBtn: 'Remove',

    /**
     * Employees booking tab, swap employees dialog
     */
    swapEmployeesBtn: 'Swap bookings',
    swapEmployeesDialogTitle: 'Swap bookings',
    swapEmployeesDialogAccept: 'Apprive',

    /**
     * Employees booking tab, book for employee dialog
     */
    bookForEmployeeBtn: 'Book',
    bookForEmployeeDialogTitle: 'Book',
    bookForEmployeeAccept: 'Book',
    bookForEmployeeSelectLabel: 'Select an Employee',

    /**
     * Company booking tab
     */
    individualCompanyTitle: 'Individual company bookings',
    bookings: 'Bookings',
    timeframe: 'Full session',

    /**
     * Company booking tab, request new company booking dialog
     */
    requestNewBookingBtn: 'Request new booking',
    requestNewBookingTitle: 'Request booking for your company',
    breakAt: 'Break at',
    bookingDeadline: 'Booking deadline (hours)',
    makeRequestBtn: 'Make request',

    /**
     * Company booking tab, cancel company booking dialog
     */
    cancelCompanyBookingBtn: 'Cancel',
    cancelCompanyBookingDialogTitle: 'Cancel company booking?',
    cancelCompanyBookingDialogAccept: 'Cancel booking',
    cancelCompanyBookingDialogTxtFree: 'Are you sure?',
    cancelCompanyBookingDialogTxtSemiCost: 'It will cost 50% to cancel',
    cancelCompanyBookingDialogTxtExpensiveCost: 'It will cost 100% to cancel',

    /**
     * Company booking tab, request edit company booking
     */
    requestEditCompanyBookingBtn: 'Request edit',
    requestEditCompanyBookingDialogTitle: 'Request company booking edit',
    requestEditCompanyBookingDialogChangeDate: 'Change date',
    requestEditCompanyBookingDialogChangeStart: 'Change start',
    requestEditCompanyBookingDialogChangeEnd: 'Change end',
    requestEditCompanyBookingDialogAcceptBtn: 'Send request',
    requestEditCompanyBookingDialogErrorBeforeEnd: 'Must be before end',
    requestEditCompanyBookingDialogErrorAfterStart: 'Must be after start',

    /**
     * Company booking tab, request whatever
     */
    requestWhateverDialogBtn: 'Send request',
    requestWhateverDialogAcceptBtn: 'Send',
    requestWhateverEmployeeDialogTitle: 'Request',
    requestWhateverEmployeeDialogPlaceholder: 'Write comment',
    requestWhateverDialogDescription:
      'You have picked the following booking(s)/ timseslot which is after the deadline.',
    requestWhateverDialogDescriptionComment: 'Send us a request with a comment',

    /**
     * Company employees tab
     */
    vouchersUsed: 'Vouchers left',
    vouchersLeft: 'Vouchers left',
    permission: 'Permission',
    noEmployees: 'No employees has signed up',
    removeCompanyEmployeeBtn: 'Remove employee',
    removeCompanyEmployeeDialogTitle: 'Remove employee',
    removeCompanyEmployeeDialogAccept: 'Remove',

    /**
     * Success snackbar
     */
    bookedEmployeebookingSuccess: 'The booking has been made',
    editedEmployeebookingSuccess: 'The booking has been moved',
    editedMotherbookingSuccess: 'The booking has been moved',
    cancelledEmployeebookingSuccess: 'The booking has been cancelled',
    requestMotherbookingSuccess: 'The request has been sent',
    deletedEmployeeSuccess: 'The employee has been removed',
    requestSentSuccess: 'The request has been sent',
    swappedEmployeebookingsSuccess: 'The bookings has been swapped',
  },

  DK: {
    /**
     * Global
     */
    date: 'Dato',
    name: 'Navn',
    full_name: 'Fulde navn',
    time: 'Tid',
    type: 'Type',
    masseure: 'Massør',
    close: 'Luk',
    with: 'med',
    noAvailableCompanyBookings: 'Ingen tilgængelig firmabookings',
    fullname: 'Fulde navn',
    phone: 'Telefon',
    email: 'Email',
    comment: 'Kommentar',
    from: 'fra',
    to: 'til',
    pickDate: 'Vælg dato',
    point_in_time: 'Tidspunkt',
    service: 'Ydelse',
    yes: 'Ja',
    no: 'Nej',
    company_name: 'Virksomhedens navn',
    email_domain: 'Email Domæne',
    mobile_number: 'Mobilnummer:',
    /**
     * Login
     */
    /* Login */
    login_email_text: 'Indtast din arbejdsmail',
    login_email_warning: 'Indtast en gyldig email adresse',
    login_password_warning: 'Skal bestå af mindst 4 tegn',
    login_cname_warning: 'Indtast din navn',
    login_cphone_warning: 'Indtast din mobil nummer',

    login_access_denied:
      'Vi kunne desværre ikke genkende din email. Du kan genåbne siden og forsøge igen med din din arbejdsemail',
    login_access_denied_linktext: 'Tryk her for at prøve igen',
    login_choose_department: 'Vælg afdeling',
    login_sign_up: 'Opret bruger',
    login_password: 'Kodeord',
    login_not_recognised_domain: `
    Vi kunne ikke genkende domænet på din arbejdsmail. Er jeres
    firma en del af et kontorfællesskab?`,
    login_create_password: 'Opret venligst et kodeord',
    login_logging_in: 'Logger ind...',
    login_create_user_header: 'Opret dig som medarbejder hos',

    login_create_user_phone_number: 'Privat Mobilnummer med MobilePay:',
    login_create_user_phone_number_explanation: `Vi sender bekræftelse og reminder til dette nummer`,
    login_please_fill_all_fields: 'Udfyld venligst alle felter',
    /**
     * Text outside the tabs, e.g. top bar
     */
    headlinePartOne: 'Firma',
    headlinePartTwo: 'massage til ',
    chooseTimeSlot: 'Vælg et tidspunkt',
    signOut: 'Log ud',
    danishLang: 'Dansk',
    englishLang: 'Engelsk',

    /**
     * Menu/tabs
     */
    bookTab: 'Vælg tid',
    myBookingsTab: 'Se dine tider',
    employeesBookingTab: 'Bookings',
    companyBookingTab: 'Firmabookings',
    companyEmployeesTab: 'Tilmeldte medarbejdere',

    /**
     * Book tab, timeslots overview
     */
    nextStep: 'Fortsæt',
    massageWith: 'minutters massage med',
    deadlinePartOne: 'Adgang for aflysning af booking lukkes',
    deadlinePartTwo: 'timer før',

    /**
     * Book tab, timeslots overview, therapists dialog
     */
    education: 'Uddannelse',
    bio: 'Bio',

    /**
     * Book tab, employee form
     */
    yourDetails: 'Dine oplysninger',
    commentSuggestion: 'f.eks. øm nakke',
    acceptTermAndPrivacy:
      'Jeg accepterer handelsbetingelserne og privatlivspolitikken',
    acceptNoConvidSymptoms: 'Jeg bekræfter at jeg ikke har symptomer på corona',
    bookBtn: 'Book',
    backBtn: 'Tilbage',

    /**
     * My bookings tab
     */
    activeBooking: 'Aktive bookings',
    bookingHistory: 'Tidligere bookings',
    show: 'Vis',
    hide: 'Skjul',
    editBookingBtn: 'Rediger booking',
    cancelBookingBtn: 'Aflys booking',
    noBookings: 'Du har ingen bookings',

    /**
     * My bookings tab, cancel dialog
     */
    cancelBookingDialogTitle: 'Slet booking',
    cancelBookingDeadlineOne: 'Det sgu dyrt at aflyse',
    cancelBookingDeadlineTwo: 'Det koster en lille smule at aflyse',
    cancelBookingDeadlineThree: 'Er du sikker på du vil aflyse din booking?',
    cancelBookingDialogText:
      'Du bør være opmærksomhed på følgende før du sletter din bookings bla bla bla bla bla bla bla...',
    cancelBookingDialogDetails: 'Du er ved at slette din booking.',
    cancelBookingDialogCancelBtn: 'Aflys booking',

    /**
     * Employees booking tab
     */
    availableBookings: 'Tilmeldinger',
    availableTimeslot: 'Ledig',
    noTimeSlotsCreated: 'Ingen tider oprettet',
    fullSession: 'Tidspunkt',
    swapDescription: 'Vælg to medarbejdere for at bytte',
    bookDescription: 'Vælg en ledig tid, der passer dig',
    editDescription: 'Vælg den booking, du ønsker at redigere',
    requestDescription:
      'Er deadline overskredet? Vi har din ryg. Vælg det ønskede tidspunkt og send os en anmodning.',
    /**
     * Employees booking tab, remove employee dialog
     */
    removeEmployeesBookingBtn: 'Aflys medarbejder tiden',
    removeEmployeeDialogTitle: 'Fjern medarbejder fra tidspunkt?',
    removeEmployeeDialogRemoveBtn: 'Fjern',

    /**
     * Employees booking tab, swap employees dialog
     */
    swapEmployeesBtn: 'Ombyt bookings',
    swapEmployeesDialogTitle: 'Ombyt bookings',
    swapEmployeesDialogAccept: 'Godkend',

    /**
     * Employees booking tab, book for employee dialog
     */
    bookForEmployeeBtn: 'Book',
    bookForEmployeeDialogTitle: 'Book',
    bookForEmployeeAccept: 'Book',
    bookForEmployeeSelectLabel: 'Vælg en medarbejder',

    /**
     * Company booking tab
     */
    individualCompanyTitle: 'Enkelte virksomhedsbookings',
    bookings: 'Bookings',
    timeframe: 'Varighed',

    /**
     * Company booking tab, request new company booking dialog
     */
    requestNewBookingBtn: 'Anmod om booking',
    requestNewBookingTitle: 'Anmod om booking for din virksomhed',
    breakAt: 'Pause start',
    bookingDeadline: 'Angiv booking deadline (timer)',
    makeRequestBtn: 'Anmod',

    /**
     * Company booking tab, cancel company booking dialog
     */
    cancelCompanyBookingBtn: 'Fjern',
    cancelCompanyBookingDialogTitle: 'Fjern virksomhedsbooking?',
    cancelCompanyBookingDialogAccept: 'Fjern booking',
    cancelCompanyBookingDialogTxtFree: 'Er du sikker?',
    cancelCompanyBookingDialogTxtSemiCost: 'Det koster 50% at aflyse.',
    cancelCompanyBookingDialogTxtExpensiveCost: 'Det koster 100% at aflyse.',

    /**
     * Company booking tab, request edit company booking
     */
    requestEditCompanyBookingBtn: 'Anmod om redigering',
    requestEditCompanyBookingDialogTitle:
      'Anmod om redigering af virksomhedsbooking',
    requestEditCompanyBookingDialogChangeDate: 'Ændre dato',
    requestEditCompanyBookingDialogChangeStart: 'Ændre starttidspunkt',
    requestEditCompanyBookingDialogChangeEnd: 'Ændre sluttidspunkt',
    requestEditCompanyBookingDialogAcceptBtn: 'Send anmodning',
    requestEditCompanyBookingDialogErrorBeforeEnd: 'Skal være før slut',
    requestEditCompanyBookingDialogErrorAfterStart: 'Skal være før start',

    /**
     * Company booking tab, request whatever
     */
    requestWhateverDialogBtn: 'Send anmodning',
    requestWhateverDialogAcceptBtn: 'Send',
    requestWhateverEmployeeDialogTitle: 'Anmodning',
    requestWhateverEmployeeDialogPlaceholder: 'Skriv kommentar',
    requestWhateverDialogDescription:
      'Du har valgt tid(er) der er efter deadlinen.',
    requestWhateverDialogDescriptionComment:
      'Send en anmodning til os med en kommentar.',

    /**
     * Company employees tab
     */
    vouchersUsed: 'Klip tilbage',
    vouchersLeft: 'Klippekort',
    permission: 'Tilladelse',
    noEmployees: 'Ingen medarbejdere registreret',
    removeCompanyEmployeeBtn: 'Fjern medarbejder',
    removeCompanyEmployeeDialogTitle: 'Fjern medarbejder',
    removeCompanyEmployeeDialogAccept: 'Fjern',

    /**
     * Success snackbar
     */
    bookedEmployeebookingSuccess: 'Bookingen blev lavet',
    editedEmployeebookingSuccess: 'Bookingen blev flyttet',
    editedMotherbookingSuccess: 'Anmodningen blev sendt',
    cancelledEmployeebookingSuccess: 'Bookingen blev aflyst',
    requestMotherbookingSuccess: 'Anmodningen blev sendt',
    deletedEmployeeSuccess: 'Medarbejderen er blevet fjernet',
    requestSentSuccess: 'Anmodningen blev sendt',
    swappedEmployeebookingsSuccess: 'Bestillingerne har byttet plads',
  },
};

export const LanguageContext = React.createContext(null);
