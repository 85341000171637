import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../constants/colors';
import { BreakPoints } from '../../constants/breakpoints';


export const CompanyBookingStyle = makeStyles((theme) => ({
    requestBooking: {
        [theme.breakpoints.down(BreakPoints.main)]: {
            position: 'fixed',
            bottom: '0',
            width: 'calc(100% - 48px)',
            transform: 'translateY(-50%)',

            '& button': {
                border: '1px solid',
                borderRadius: '5px',
                color: Colors.primaryColor,
                width: '100%',
            },
        },
        textAlign: 'end',

        '& div span': {
            display: 'inline-flex'
        },

        '& button': {
            border: '1px solid',
            borderRadius: '5px',
            color: Colors.primaryColor,

            '& .fa': {
                fontSize: '24px',
                marginRight: '12px',
            },
        },

    },
    tableStyle: {
        '& div': {
            color: Colors.primaryColor,
            fontSize: '16px',
            fontWeight: '600',
            marginBottom: '8px'
        },
        
        '& div:nth-child(2)': {
            boxShadow: 'none',
        },
    },

    DropButtonContentHeight: {
        height: 'calc(38px * 4)'
    },

    ContentHeight: {
        '&>div': {
            height: '148px !important',
          },
    },
    // EditCancelBookingBtn: {
    //     marginTop: '40',
    //     display: 'inline-flex',
    //     alignItems: 'baseline',
    //     '& span': {
    //         color: Colors.primaryColor,
    //         fontSize: '16px',
    //         fontWeight: '600',
    //         cursor: 'pointer',
    //     },
    //     '& span:nth-child(2)': {
    //         marginRight: '76px',
    //     },
    //     '& span:nth-child(2n)': {
    //         marginLeft: '10px',
    //     }
    // },

    noBookings: {
        fontSize: '16 !important',
        fontWeight: '300 !important',
        color: Colors.grey + ' !important',
        marginTop: '16px !important',
    },
}));