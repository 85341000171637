import React, { useState, useContext } from 'react';
import { Dialog, DialogContent, Icon } from '@material-ui/core';
import { BookAppointmentView } from '../../../../bookappointment/book_appointment_view';
import { LanguageContext } from '../../../../../language_context';
import { generalStyle } from '../../../../../generalStyle';
import CloseIcon from '@material-ui/icons/Close';

export const ChangeBookingDialog = ({ company, employeeDetails, Admin, selectedBooking, getEmployee, getCompanyFull, breakpoint, snackbarshow }) => {
    const { lang } = useContext(LanguageContext);
    const [open, setOpen] = useState(false);
    const generalClass = generalStyle();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dialogProps = { company, employeeDetails, Admin, handleClose, selectedBooking, getEmployee, getCompanyFull, breakpoint, snackbarshow };

    return (
        <div>
            {breakpoint ? (
                <span onClick={handleClickOpen}>
                    {lang.editBookingBtn}
                </span>
            ) : (
                <span onClick={handleClickOpen}>
                    {lang.editBookingBtn}
                    <Icon className="fa fa-edit" />
                </span>
            )}
            <Dialog
                className={generalClass.dialogWrapFullscreen}
                open={open}
                maxWidth='md'
                fullWidth='true'
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                {breakpoint && <CloseIcon onClick={handleClose} className={generalClass.CloseIconFullscreen}/>}
                <DialogContent>
                    <BookAppointmentView  {...dialogProps} />
                </DialogContent>
            </Dialog>
        </div>
    );
}