import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TimeSlotsStyle, TimeSlotBtn } from '../time_slots_style';
// import { LanguageContext } from '../../../../language_context';

export const TimeslotItem = ({
  item,
  handleCheckLimitBookings,
  Admin,
  company,
  employeeDetails,
}) => {
  const timeSlotsClass = TimeSlotsStyle();

  const isTimeSlotTakenByLoggedinEmployee =
    item.CustomerId === employeeDetails.CustomerId;

  const btnText =
    company &&
    (company.companyId === 333 ||
      company.companyId === 334 ||
      company.companyId === 465) &&
    !isTimeSlotTakenByLoggedinEmployee
      ? 'Optaget'
      : item.Name;

  const openTimeslotBtn = (
    <TimeSlotBtn
      key={item.BookingId}
      classes={{
        root: item.Selected
          ? timeSlotsClass.selected
          : timeSlotsClass.notSelected,
      }}
      name="appointment"
      onClick={() => {
        handleCheckLimitBookings(item);
      }}
    >
      {item.Start}
    </TimeSlotBtn>
  );

  const notOpenTimeSlotBtn = (
    <TimeSlotBtn
      disabled
      variant="contained"
      key={item.BookingId}
      classes={{
        root: item.Selected
          ? timeSlotsClass.selected
          : timeSlotsClass.notSelected,
      }}
      name="appointment"
      onClick={() => {
        handleCheckLimitBookings(item);
      }}
    >
      {item.Start}
    </TimeSlotBtn>
  );

  const moveTimeslotBtn = (
    <TimeSlotBtn
      classes={{ root: timeSlotsClass.move }}
      key={item.BookingId}
      disabled
      variant="contained"
    >
      {btnText}
    </TimeSlotBtn>
  );

  const timeSlotTakenBtn = (
    <TimeSlotBtn key={item.BookingId} disabled variant="contained">
      {btnText}
    </TimeSlotBtn>
  );

  return (
    <Grid item xs={4}>
      {item.Available
        ? item.Open
          ? openTimeslotBtn
          : notOpenTimeSlotBtn
        : item.Move
        ? moveTimeslotBtn
        : timeSlotTakenBtn}
    </Grid>
  );
};
