import React, { useState, useContext } from 'react';
import { Button, TextField } from '@material-ui/core';
import { UserInfoFormStyle } from './user_info_form_style';
import { GreenCheckBox } from '../../../generalStyle';
import { LanguageContext } from '../../../language_context';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AuthService from '../../../services/auth.service';

export const UserInfoForm = ({
  formData,
  handleBackStep,
  userInfo,
  handleSetUserInfoData,
  selectedBooking,
  getEmployee,
  getCompanyFull,
  breakpoint,
  snackbarshow,
}) => {
  const { lang } = useContext(LanguageContext);
  const userInfoFormClass = UserInfoFormStyle();
  // No idea if this is the most efficient way of form validation, but it werks.
  const [errorName, setErrorName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPhone, setErrorPhone] = useState('');

  const [antiSpam, setAntiSpam] = useState(false);

  const bookAppointment = async () => {
    if (antiSpam) return;
    setAntiSpam(true);
    var body = {
      Customer_ID: userInfo.CustomerId,
      ServiceDate: formData.Date + 'T' + formData.Start + ':00',
      ProblemDescription: userInfo.Comment,
    };
    if (selectedBooking === undefined) {
      body.MotherBookingId = formData.MotherBookingId;
      const bookData = await AuthService.fetch(
        `${AuthService.API_URL}/bookemployee`,
        {
          method: 'post',
          data: body,
        }
      );
      if (bookData.data.status === 'Success') {
        getCompanyFull();
        getEmployee();
        snackbarshow(lang.bookedEmployeebookingSuccess);
      } else {
        getCompanyFull();
        getEmployee();
        snackbarshow('error');
      }
    } else {
      body.BookingId = selectedBooking.BookingId;
      body.MotherBookingId = formData.MotherBookingId;
      const editData = await AuthService.fetch(
        `${AuthService.API_URL}/editbooking`,
        {
          method: 'post',
          data: body,
        }
      );
      if (editData.data.status === 'Success') {
        snackbarshow(lang.editedEmployeebookingSuccess);
        getCompanyFull();
        if (getEmployee !== undefined) {
          getEmployee();
        }
      }
    }
    setAntiSpam(false);
  };

  const isNameValid = (e) => {
    if (e.target.value.length > 0) {
      setErrorName('');
    } else setErrorName('Enter your name');
  };

  const isEmailValid = (e) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (e.target.value.length === 0) {
      setErrorEmail('Enter your email');
    } else if (!re.test(String(e.target.value).toLowerCase())) {
      setErrorEmail('Incorrect email');
    } else setErrorEmail('');
  };

  const isPhoneValid = (e) => {
    if (e.target.value.length > 0) {
      setErrorPhone('');
    } else setErrorPhone('Enter your name');
  };

  function isFormValid() {
    return (
      errorName === '' &&
      errorEmail === '' &&
      errorPhone === '' &&
      userInfo.TermsAccept === true &&
      userInfo.CoronaAccept === true
    );
  }

  const responsive = (ScreenSize) => {
    if (breakpoint && ScreenSize === 'phone') {
      return 'outlined';
    } else if (!breakpoint && ScreenSize === 'desktop') {
      return 'standard';
    } else {
      return 'standard';
    }
  };

  if (!userInfo.CanEmployeeBook) {
    return (
      <div className={userInfoFormClass.rootForm}>
        <h2>
          Beklager! Du har ikke flere klip tilbage. <br /> Kontakt venligst den
          ansvarlige for aftalen.
        </h2>
      </div>
    );
  }

  return (
    <div className={userInfoFormClass.rootForm}>
      <div className={userInfoFormClass.mobileHeading}>
        <Button size="small" onClick={handleBackStep}>
          <ArrowBackIosIcon></ArrowBackIosIcon>
          {lang.backBtn}
        </Button>
        <h2>{lang.UserInfoHeader}</h2>
      </div>
      <form autoComplete="off">
        <div>
          <TextField
            name="FullName"
            label={lang.fullname}
            fullWidth
            disabled
            onChange={(e) => {
              handleSetUserInfoData(e);
              isNameValid(e);
            }}
            defaultValue={userInfo.FullName}
            error={errorName === '' ? false : true}
            helperText={errorName}
            variant={responsive('phone')}
          />
          <TextField
            fullWidth
            disabled
            type="number"
            onChange={(e) => {
              handleSetUserInfoData(e);
              isPhoneValid(e);
            }}
            name="Phone"
            label={lang.phone}
            defaultValue={userInfo.Phone}
            error={errorPhone === '' ? false : true}
            helperText={errorPhone}
            variant={responsive('phone')}
          />
          <TextField
            fullWidth
            disabled
            onChange={(e) => {
              handleSetUserInfoData(e);
              isEmailValid(e);
            }}
            error={errorEmail === '' ? false : true}
            helperText={errorEmail}
            label={lang.email}
            name="Email"
            defaultValue={userInfo.Email}
            variant={responsive('phone')}
          />
          <TextField
            fullWidth
            onChange={handleSetUserInfoData}
            id="outlined-multiline-static"
            name="Comment"
            label={lang.comment}
            multiline
            rows={5}
            value={userInfo.Comment}
            placeholder={lang.commentSuggestion}
            variant="outlined"
          />
        </div>
        <div className={userInfoFormClass.checkBoxSection}>
          <div>
            <p className={userInfoFormClass.checkBoxSectionText}>
              {lang.acceptTermAndPrivacy}
            </p>
            <GreenCheckBox
              name="TermsAccept"
              onChange={handleSetUserInfoData}
              checked={userInfo.TermsAccept}
            />
          </div>
          <div>
            <p className={userInfoFormClass.checkBoxSectionText}>
              {lang.acceptNoConvidSymptoms}
            </p>
            <GreenCheckBox
              name="CoronaAccept"
              onChange={handleSetUserInfoData}
              checked={userInfo.CoronaAccept}
            />
          </div>
        </div>
        <div>
          {isFormValid() ? (
            <Button
              disabled={antiSpam}
              onClick={() => {
                bookAppointment();
              }}
              className={`${userInfoFormClass.confirmInputBtn} ${userInfoFormClass.activeConfirmInputBtn}`}
            >
              {lang.bookBtn}
            </Button>
          ) : (
            <Button className={userInfoFormClass.confirmInputBtn}>
              {lang.bookBtn}
            </Button>
          )}
          <Button
            className={userInfoFormClass.backButtonDesktop}
            size="small"
            onClick={handleBackStep}
          >
            {lang.backBtn}
          </Button>
        </div>
      </form>
    </div>
  );
};
