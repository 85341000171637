import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { BookingOverviewStyle } from './booking_overview_style';
import {
  generalStyle,
  StyledTableCell,
  GreenCheckBox,
} from '../../../generalStyle';
import AuthService from '../../../services/auth.service';
import { LanguageContext } from '../../../language_context';
import { EmployeeActions } from './employeeactions/employee_actions';
import prettydate from '../../common/commonfunctions';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export const BookingOverview = ({
  employeeDetails,
  setEmployeeDetails,
  company,
  handleSetNavIndex,
  getCompanyFull,
  getEmployee,
  breakpoint,
  currentUser,
  snackbarshow,
  setLoading,
}) => {
  const API_URL = AuthService.API_URL;
  const { lang } = useContext(LanguageContext);
  const bookingOverviewClass = BookingOverviewStyle();
  const generalClass = generalStyle();
  const [selected, setSelected] = useState(undefined); // selected booking
  const [selectedBooking, setSelectedBooking] = useState(undefined); // selected booking
  const [showHistory, setShowHistory] = useState(false);
  const [antiSpam, setAntiSpam] = useState(false);

  useEffect(() => {
    let Employee = AuthService.fetch(
      `${API_URL}/getemployeedata?Customer_ID=${currentUser.Customer_ID}`
    );
    if (Employee.data !== undefined) {
      setEmployeeDetails(Employee.data);
    }
  }, []);

  const handleSelect = (bookingId) => {
    if (selected === bookingId) {
      setSelected(undefined);
      setSelectedBooking(undefined);
    } else {
      setSelected(bookingId);
      setSelectedBooking(
        employeeDetails.ActiveBookings.find((b) => b.BookingId === bookingId)
      );
    }
  };

  const handleDeleteBooking = async () => {
    if (antiSpam) return;
    setLoading(true);
    setAntiSpam(true);
    let body = {
      BookingId: selected,
    };
    const cancelData = await AuthService.fetch(
      `${AuthService.API_URL}/cancelemployeebooking`,
      {
        method: 'post',
        data: body,
      }
    );

    if (cancelData.data.status === 'Success') {
      setEmployeeDetails({
        ...employeeDetails,
        ActiveBookings: employeeDetails.ActiveBookings.filter(
          (item) => item.BookingId !== selected
        ),
      });
      setSelected(undefined);
      getCompanyFull(); // Refreshes "Book" tab, works but not the most efficient way.
      setLoading(false);
      snackbarshow(lang.cancelledEmployeebookingSuccess);
    }
    setLoading(false);
  };
  const openPhoneMenu = (e, bookingId) => {
    if (e.target.type !== 'checkbox') {
      !e.target.parentNode.classList.contains(
        `${generalClass.DropButtonActive}`
      )
        ? e.target.parentNode.classList.add(
            `${generalClass.DropButtonActive}`,
            `${bookingOverviewClass.ContentHeight}`
          )
        : e.target.parentNode.classList.remove(
            `${generalClass.DropButtonActive}`,
            `${bookingOverviewClass.ContentHeight}`
          );
    } else {
      handleSelect(bookingId);
    }
  };

  const cancelBookingDialogProps = {
    handleDeleteBooking,
    selectedBooking,
    company,
    breakpoint,
    snackbarshow,
  };
  const changeBookingDialogProps = {
    company,
    employeeDetails,
    handleSetNavIndex,
    selectedBooking,
    getEmployee,
    getCompanyFull,
    breakpoint,
    snackbarshow,
  };

  return (
    <div>
      {breakpoint ? (
        <div className={generalClass.phoneContentWrap}>
          <div className={generalClass.headings}>
            {lang.activeBooking} ({employeeDetails.ActiveBookings.length})
          </div>
          <div className={bookingOverviewClass.buttonWrap}>
            {employeeDetails.ActiveBookings.map((row, index) => (
              <div key={index} className={generalClass.DropButton}>
                <Button onClick={(e) => openPhoneMenu(e, row.BookingId)}>
                  <GreenCheckBox checked={selected === row.BookingId} />
                  <span className={'DropButtonTitle'}>{row.Date}</span>
                  <ArrowDropDownIcon className={'SVGBut'}></ArrowDropDownIcon>
                </Button>
                <div className={bookingOverviewClass.DropButtonContentHeight}>
                  <div>
                    <p>{row.Start}</p>
                    <p>{row.STypeName}</p>
                    <p>{row.Masseuse}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className={generalClass.headings}>
            {lang.bookingHistory} ({employeeDetails.PreviousBookings.length})
          </div>
          <div>
            {employeeDetails.PreviousBookings.map((row, index) => (
              <div
                key={index + 'b'}
                className={`${generalClass.DropButton} ${bookingOverviewClass.BookingDropButtonPrev}`}
              >
                <Button onClick={(e) => openPhoneMenu(e, row.BookingId)}>
                  <span className={'DropButtonTitle'}>{row.Date}</span>
                  <ArrowDropDownIcon className={'SVGBut'}></ArrowDropDownIcon>
                </Button>
                <div className={bookingOverviewClass.DropButtonContentHeight}>
                  <div>
                    <p>{row.Start}</p>
                    <p>{row.STypeName}</p>
                    <p>{row.Masseuse}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <div className={bookingOverviewClass.tableStyle}>
            <div className={bookingOverviewClass.overviewTitle}>
              {lang.activeBooking} ({employeeDetails.ActiveBookings.length})
            </div>
            {employeeDetails.ActiveBookings.length > 0 ? (
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>{lang.date}</StyledTableCell>
                      <StyledTableCell>{lang.time}</StyledTableCell>
                      <StyledTableCell>{lang.type}</StyledTableCell>
                      <StyledTableCell>{lang.masseuse}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employeeDetails.ActiveBookings.map((row, index) => (
                      <TableRow
                        key={index + 'a'}
                        className={generalClass.clickable}
                        value={row.BookingId}
                        onClick={() => handleSelect(row.BookingId)}
                      >
                        <StyledTableCell padding="checkbox">
                          <GreenCheckBox checked={selected === row.BookingId} />
                        </StyledTableCell>
                        <StyledTableCell>
                          {prettydate(row.Date)}
                        </StyledTableCell>
                        <StyledTableCell>{row.Start}</StyledTableCell>
                        <StyledTableCell>{row.STypeName}</StyledTableCell>
                        <StyledTableCell>{row.Masseuse}</StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className={bookingOverviewClass.noBookings}>
                {lang.noBookings}
              </div>
            )}
          </div>
          <br></br>
          <div className={bookingOverviewClass.tableStyle}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <div
                  className={`${generalClass.clickable} ${bookingOverviewClass.overviewTitle}`}
                  onClick={() => setShowHistory(!showHistory)}
                >
                  {lang.bookingHistory} (
                  {employeeDetails.PreviousBookings.length})
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  className={bookingOverviewClass.showHistoryBtn}
                  onClick={() => setShowHistory(!showHistory)}
                >
                  {showHistory ? lang.hide : lang.show}
                </div>
              </Grid>
            </Grid>
            {employeeDetails.PreviousBookings.length > 0 && showHistory ? (
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>{lang.date}</StyledTableCell>
                      <StyledTableCell>{lang.time}</StyledTableCell>
                      <StyledTableCell>{lang.type}</StyledTableCell>
                      <StyledTableCell>{lang.masseure}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employeeDetails.PreviousBookings.map((row, index) => (
                      <TableRow key={index + 'c'}>
                        <StyledTableCell>
                          {prettydate(row.Date)}
                        </StyledTableCell>
                        <StyledTableCell>{row.Start}</StyledTableCell>
                        <StyledTableCell>{row.STypeName}</StyledTableCell>
                        <StyledTableCell>{row.Masseuse}</StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div />
            )}
          </div>
        </div>
      )}
      <EmployeeActions
        {...{ selected, cancelBookingDialogProps, changeBookingDialogProps }}
      />
    </div>
  );
};
