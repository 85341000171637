import React, { useState, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { cancelEmployeeBookingDialogStyle } from './cancel_employee_booking_dialog_style';
import AuthService from '../../../../../services/auth.service';
import { LanguageContext } from '../../../../../language_context';
import { generalStyle } from '../../../../../generalStyle';
import CloseIcon from '@material-ui/icons/Close';

export const CancelEmployeeBookingDialog = ({
  selectedBookings,
  handleRefreshData,
  breakpoint,
  snackbarshow,
}) => {
  const { lang } = useContext(LanguageContext);
  const cancelBookingDialogClass = cancelEmployeeBookingDialogStyle();
  const generalClass = generalStyle();
  const [open, setOpen] = useState(false);
  const [antiSpam, setAntiSpam] = useState(false);

  const handleDeleteBooking = () => {
    if (antiSpam) return;
    setAntiSpam(true);
    selectedBookings.forEach((booking) => {
      let body = {
        BookingId: booking.BookingId,
      };
      // Delete request
      AuthService.fetch(`${AuthService.API_URL}/cancelemployeebooking`, {
        method: 'post',
        data: body,
      })
        .then((res) => {
          handleRefreshData();
          snackbarshow(lang.cancelledEmployeebookingSuccess);
        })
        .catch((res) => {
          console.log(res);
        });
    });
    setAntiSpam(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span onClick={handleClickOpen}>{lang.removeEmployeesBookingBtn}</span>
      <Dialog
        className={generalClass.dialogWrap}
        maxWidth="xs"
        fullWidth="true"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {breakpoint && <CloseIcon className={generalClass.CloseIcon} />}
        <DialogTitle id="alert-dialog-title">
          {lang.removeEmployeeDialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selectedBookings.map((booking, index) => (
              <div key={index}>
                <div>
                  <b>{lang.email}:</b> {booking.Email}
                </div>
                <div>
                  <b>{lang.name}:</b> {booking.Name}
                </div>
                <div>
                  <b>{lang.phone}:</b> {booking.Phone}
                </div>
                <div>
                  <b>{lang.date}:</b> {booking.Date}
                </div>
                <div>
                  <b>{lang.point_in_time}:</b> {booking.Start} - {booking.End}
                </div>
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
        {!breakpoint ? (
          <DialogActions>
            <Button onClick={handleClose}>{lang.close}</Button>
            <button
              className={cancelBookingDialogClass.cancelBtn}
              onClick={() => {
                handleClose();
                handleDeleteBooking();
              }}
              disabled={antiSpam}
            >
              {lang.removeEmployeeDialogRemoveBtn}
            </button>
          </DialogActions>
        ) : (
          <DialogActions>
            <button
              className={cancelBookingDialogClass.cancelBtn}
              onClick={() => {
                handleClose();
                handleDeleteBooking();
              }}
              disabled={antiSpam}
            >
              {lang.removeEmployeeDialogRemoveBtn}
            </button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
