import { makeStyles } from "@material-ui/core/styles";
import { Colors } from '../../../constants/colors';
import { BreakPoints } from '../../../constants/breakpoints';

export const UserInfoFormStyle = makeStyles((theme) => ({
    confirmInputBtn: {
        width: '100%',
        marginTop: '15px',
        backgroundColor: Colors.lightGrey,
        color: Colors.grey,
        height: '40px',
        borderRadius: '5px',
        border: '1px solid ' + Colors.lightGrey,
        fontWeight: '600',
    },

    activeConfirmInputBtn: {
        backgroundColor: Colors.primaryGreen + " !important",
        color: Colors.primaryColor,
        cursor: 'pointer',
    },

    // checkBoxLabel: {
    //     color: Colors.primaryColor,
    //     fontSize: '14px',
    // },

    checkBoxSection: {
        '& div': {
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
        },

        '& span': {
            height: 'max-content',
        }
    },

    checkBoxSectionText: {
        color: Colors.primaryColor,
        fontSize: '14px',
        padding: '8px',
        margin: '0',

        [theme.breakpoints.down(BreakPoints.main)]: {
            fontSize: '16px',
        }
        // position: 'relative',
        // transform: 'translateY(50%)',
        // top: '50%',
    },

    mobileHeading: {
        display: 'none',
        maxWidth: '500px',
        width: '100%',

        '& h2': {
            textAlign: 'center',
            fontSize: '30px',
            fontWeight: '500',
            lineHeight: '30px',
            margin: '24px 0 24px 0',
        },

        '& button': {
            position: 'absolute',
            left: '0',
            padding: '6px 8px',
            transform: 'translateY(-15px)',
            color: Colors.primaryColor,
            textTransform: 'none',

            '& svg': {
                height: '12px',
            },
        },

        [theme.breakpoints.down(BreakPoints.main)]: {
            display: 'initial',
        }
    },

    backButtonDesktop: {
        [theme.breakpoints.down(BreakPoints.main)]: {
            display: 'none',
        },
    },

    rootForm: {
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'white',

        [theme.breakpoints.down(BreakPoints.main)]: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '15px',
        },

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
            [theme.breakpoints.down(BreakPoints.main)]: {
                margin: '8px 0',
            },

            // width: '50ch',
            '& label.Mui-focused': {
                color: Colors.primaryColor,
            },

            '& label.Mui-error': {
                color: Colors.error,
            },

            '& .MuiInput-underline:after': {
                borderBottomColor: Colors.primaryColor,
            },

            '& .MuiInput-underline.Mui-error:after': {
                borderBottomColor: Colors.error,
            }
        },
        '& .MuiOutlinedInput-root': {
            [theme.breakpoints.down(BreakPoints.main)]: {
                backgroundColor: Colors.transparentGrey,
            },
            
            '&.Mui-focused fieldset': {
                borderColor: Colors.primaryColor,
            },
        },

        '& form': {
            maxWidth: '500px',
        },
    },
}));