import React, { useState, useContext } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Icon
} from '@material-ui/core';
import { cancelBookingDialogStyle } from './cancel_booking_dialog_style';
import { LanguageContext } from '../../../../../language_context';
import prettydate from '../../../../common/commonfunctions';
import { generalStyle } from '../../../../../generalStyle';
import CloseIcon from '@material-ui/icons/Close';

export const CancelBookingDialog = ({ handleDeleteBooking, selectedBooking, company, breakpoint }) => {
    const { lang } = useContext(LanguageContext);
    const generalClass = generalStyle();
    const cancelBookingDialogClass = cancelBookingDialogStyle();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {breakpoint ? (
                <span onClick={handleClickOpen}>
                    {lang.cancelBookingBtn}
                </span>
            ) : (
                <span onClick={handleClickOpen}>
                    {lang.cancelBookingBtn}
                    <Icon className="fa fa-trash" />
                </span>
            )}
            <Dialog
                className={generalClass.dialogWrap}
                maxWidth='xs'
                fullWidth='true'
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                {breakpoint && <CloseIcon className={generalClass.CloseIcon}/>}
                <DialogTitle id="alert-dialog-title">{lang.cancelBookingDialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div>{cancelBookingText(company, selectedBooking, lang)}</div>
                        <br />
                        <div>{company.cancellationText}</div>
                        <br />
                        <div>{lang.cancelBookingDialogDetails}</div>
                        <div>{prettydate(selectedBooking.Date, selectedBooking.Start)}</div>
                        <div>{selectedBooking.STypeName}</div>
                        <div>{lang.with} {selectedBooking.Masseuse}</div>
                    </DialogContentText>
                </DialogContent>
            {breakpoint ? (
                <DialogActions>
                    <button className={cancelBookingDialogClass.cancelBtn} onClick={() => { handleClose(); handleDeleteBooking(selectedBooking.BookingId) }}>
                        {lang.cancelBookingDialogCancelBtn}
                    </button>
                </DialogActions>
            ) :( 
                <DialogActions>
                    <Button onClick={handleClose}>
                        {lang.close}
                    </Button>
                    <button className={cancelBookingDialogClass.cancelBtn} onClick={() => { handleClose(); handleDeleteBooking(selectedBooking.BookingId) }}>
                        {lang.cancelBookingDialogCancelBtn}
                    </button>
                </DialogActions>
            )}
            </Dialog>
        </div>
    );
}

function cancelBookingText(company, selectedBooking, lang) {
    var deadline = company.employeeBookingDeadline * 1;
    var factor = 0.5;

    var currentDate = new Date().getTime();

    var costBoundary = new Date(`${selectedBooking.Date}T${selectedBooking.Start}:00`);
    costBoundary = costBoundary.setHours(costBoundary.getHours() - deadline * factor);
    var costFreeBoundary = new Date(`${selectedBooking.Date}T${selectedBooking.Start}:00`);
    costFreeBoundary = costFreeBoundary.setHours(costFreeBoundary.getHours() - deadline);

    if (currentDate > costBoundary) {
        return lang.cancelBookingDeadlineOne;
    } else if (currentDate > costFreeBoundary) {
        return lang.cancelBookingDeadlineTwo;
    } else {
        return lang.cancelBookingDeadlineThree;
    };
}