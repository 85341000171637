import React, { useState, useEffect } from "react";
import AuthService from "./services/auth.service";
import Loading from "./components/common/Loading";
import { generalStyle } from "./generalStyle";
import Login from './components/common/login/Login';
import Snackbar from "./components/common/SnackbarMUI";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
// import Offline from "./components/common/Offline";
import { HomeView } from './components/homeview/home_view';
import "./sass/app.scss";

export const App = () => {
  const API_URL = AuthService.API_URL;
  const online = navigator.onLine;
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [Admin, setAdmin] = useState(false);
  const [company, setCompany] = useState(undefined);
  const [employeeDetails, setEmployeeDetails] = useState(undefined);
  const [snackopen, setOpenSnackbar] = useState(false);
  const [message, setSnackBarMessage] = useState(undefined);

  const snackbarshow = (message) => {
    setSnackBarMessage(message);
    setOpenSnackbar(false);
    setOpenSnackbar(true);
  }
  

  const getCompanyFull = async () => {
    let Company = await AuthService.fetch(`${API_URL}/getcompany?Company_ID=${currentUser.Company_ID}`);
    if(Company.data !== undefined){
      setCompany(Company.data);
    }
  };
  
  const getEmployee = async () => {
    let Employee = await AuthService.fetch(`${API_URL}/getemployeedata?Customer_ID=${currentUser.Customer_ID}`);
    if(Employee.data !== undefined){
      setEmployeeDetails(Employee.data);
    }
  }

  // every time the user changes then re-fetch all data from the API
  useEffect(() => {
    if(currentUser){
      getCompanyFull();
      getEmployee();
      if(currentUser.Admin==='true'){
        setAdmin(true);
      }else{
        setAdmin(false);
      }
    }
  }, [currentUser]);
  
  useEffect(() => {
    if(AuthService.loggedIn() && online){
      let user = AuthService.getCurrentUser();
      const GetAdminLogin = async() => {
        if(user) {
          if(user.System === "ui") {
            let response = await AuthService.fetch(`${API_URL}/adminlogin?User_ID=${user.userid}`);
            user = response.data;
          }

          setCurrentUser(user);
        }
      }

      GetAdminLogin();
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [online]);
 

  const homeviewProps = { company, employeeDetails, setCompany, setEmployeeDetails, getEmployee, getCompanyFull, Admin, currentUser, snackbarshow, setLoading};
  const loadingProps = { loading };
  const loginProps = { setCurrentUser, setLoading };

  const generalClass = generalStyle();
  /*<div className={generalClass.typography}></div>*/

  const AuthRoute = ({component: Component, ...rest}) => {
    return (
      <Route
        {...rest}
        render={({location}) => AuthService.loggedIn() && online
          ? <Component />
          : <Redirect to={{
            pathname: '/login',
            state: { from: location }
          }} />}
      />
    )
  }

  return (
    <Router>
      {loading ? (<React.Fragment><Loading {...loadingProps} /></React.Fragment>)
      :
      (<React.Fragment>
        {snackopen && <Snackbar open={snackopen} message={message} />}
        <Switch>
          {!AuthService.loggedIn() && (<Route path="/login" component={() => <Login {...loginProps} />} />)}
          <AuthRoute path="/" component={() => < HomeView {...homeviewProps} />}/>
        </Switch>
      </React.Fragment>)}
    </Router>
  );
}