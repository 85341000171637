import React, { useState, useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../language_context';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: 'red',
    bottom: '5rem',
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  },
}));

const CustomizedSnackbars = ({open, message}) => {
  const severity = message === "error" ? "error" : "success";
  const showMessage = message === "error" ? "Der er sket en fejl - prøv igen" : message

  const classes = useStyles();
  const [opensnack, setOpen] = useState(open);
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar 
      open={opensnack}
      autoHideDuration={1000}
      onClose={handleClose}
      classes={{root: classes.root}}
    >
      <Alert onClose={handleClose} severity={severity}>
        {showMessage}
      </Alert>
    </Snackbar>
  );
}
export default CustomizedSnackbars