import React, { useState } from "react";
import Lottie from 'react-lottie';
import * as animationData from './rask_loading.json';

const LoadingLottie = () => {
    const [isStopped, setIsStopped] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Lottie 
            options={defaultOptions}
            height={200}
            width={200}
            isStopped={isStopped}
            isPaused={isPaused}
        />
    );
}
export default LoadingLottie;