import React, { useContext } from 'react';
import { generalStyle } from '../../generalStyle';
import { EmployeesBookingTable } from './employeesbookingtable/employees_booking_table';
import { LanguageContext } from '../../language_context';
import { EmployeesBookingStyle } from './employeesbookingtable/employees_booking_style';
import { Grid } from '@material-ui/core';
import { RequestBookingDialog } from '../companybooking/requestbookingdialog/request_booking_dialog';
import { CompanyBookingStyle } from '../companybooking/company_booking_style';
export const EmployeesBookingView = ({
  company,
  getCompanyFull,
  handleSetNavIndex,
  breakpoint,
  snackbarshow,
}) => {
  const { lang } = useContext(LanguageContext);
  const generalClass = generalStyle();
  const employeesBookingClass = EmployeesBookingStyle();
  const companyBookingClass = CompanyBookingStyle();
  const requestBookingDialogProps = { company, breakpoint, snackbarshow };

  return (
    <div className={breakpoint ? generalClass.phoneContentWrap : ''}>
      <div
        className={`${companyBookingClass.requestBooking} ${generalClass.clickable}`}
      >
        <RequestBookingDialog {...requestBookingDialogProps} />
        <br></br>
      </div>
      <div className={employeesBookingClass.sectionHeader}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={2}>
            <div>
              <span className={employeesBookingClass.tableHeader}>
                {lang.date}
              </span>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div>
              <span className={employeesBookingClass.tableHeader}>
                {lang.fullSession}
              </span>
            </div>
          </Grid>

          <Grid item xs={4}>
            <div>
              <span className={employeesBookingClass.tableHeader}>
                {lang.service}
              </span>
            </div>
          </Grid>

          <Grid item xs={2}>
            <div>
              <span className={employeesBookingClass.tableHeader}>
                {lang.masseure}
              </span>
            </div>
          </Grid>
          {company.hasEmployeeBookings === true && (
            <Grid item xs={2}>
              <div>
                <span className={employeesBookingClass.tableHeader}>
                  {lang.availableBookings}
                </span>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      <br></br>
      {company.bookings.length > 0 ? (
        company.bookings.map((parentBooking, index) => {
          const employeesBookingTableProps = {
            parentBooking,
            company,
            getCompanyFull,
            handleSetNavIndex,
            breakpoint,
            snackbarshow,
          };
          return (
            <EmployeesBookingTable
              key={index + 'd'}
              {...employeesBookingTableProps}
            />
          );
        })
      ) : (
        <div>{lang.noAvailableCompanyBookings}</div>
      )}
    </div>
  );
};
