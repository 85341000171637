import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import LoadingLottie from './lottie/Lottie';

const Loading = ({loading}) => {
    return (
        <LoadingOverlay
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 1)'
            })
          }} 
          active={loading}
          spinner={<LoadingLottie />}
          text='Indlæser...'>
        </LoadingOverlay>
    );
}
export default Loading;