import axios from "axios";
import decode from 'jwt-decode';

const API_URL = window.location.hostname === 'localhost' ? "http://localhost:8080/v1/company" : "https://api.raskrask.dk/v1/company";

const login = (User_ID, Password) => {
    return fetch(`${API_URL}/login`, {
        method: "post",
        data: {
            User_ID,
            Password,
        }
    })
    .then((res) => {
        if(res.data.accessToken) {
            localStorage.setItem("companyuser", JSON.stringify(res.data));
        }
        return res.data;
    });
};

// const forgotPassword = (email) => {
//     return fetch(`${API_URL}/forgotpassword`, {
//         method: "post",
//         data: {
//             email,
//         }
//     })
//     .then((res) => {
//         console.log(res.data)
//         return "Email sent";
//     });
// };

// const resetPassword = (email, password) => {
//     return fetch(`${API_URL}/resetpassword`, {
//         method: "post",
//         data: {
//             email,
//             password,
//         }
//     })
//     .then((res) => {
//         console.log(res.data)
//         return "Password updated";
//     });
// };

const loggedIn = () => {
    const token = getToken();
    return !!token && !isTokenExpired(token)
    //return true;
}

const isTokenExpired = (token) => {
    try {
        const decoded = decode(token);
        if (decoded.exp < Date.now() / 1000) {
            return true;
        }
        else
            return false;
    }
    catch (err) {
        return false;
    }
}

const getToken = () => {
    const user = JSON.parse(localStorage.getItem('companyuser'));
    return user ? user.accessToken : false;
}

const logout = () => {
    localStorage.removeItem("companyuser");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("companyuser"));
};

const fetch = (url, options) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (loggedIn()) {
        headers['Authorization'] = 'Bearer ' + getToken()
    }

    return axios({
        headers,
        url: url,
        ...options
    })
    .then(res => res);
}

const AuthService = {
    login,
    logout,
    //forgotPassword,
    //resetPassword,
    getCurrentUser,
    loggedIn,
    isTokenExpired,
    fetch,
    API_URL
};

export default AuthService;