import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../constants/colors';

export const cancelEmployeeBookingDialogStyle = makeStyles((theme) => ({
    cancelBtn: {
        backgroundColor: Colors.primaryGreen,
        color: Colors.primaryColor,
        fontSize: '16px',
        fontWeight: '600',
        borderRadius: '5px',
        height: '36px',
        minWidth: '100px',
        border: 'none',
        cursor: 'pointer',
    },
}))  